import { Browser } from "@syncfusion/ej2-base";
import { MenuEventArgs } from "@syncfusion/ej2-navigations";
import {
    ButtonComponent,
    ChipDirective,
    ChipListComponent,
    ChipsDirective,
} from "@syncfusion/ej2-react-buttons";
import DOMPurify from "dompurify";
import moment from "moment";
import { RiskScoreTransValueRow } from "../RAFComponents/Inputs/RAFRiskMatrix/RiskScoreTransRow";
import RAFButtonComponent from "../RAFComponents/Navigation/RAFButtonComponent";
import RAFRecordInfo from "../RAFComponents/Navigation/RAFRecordInfo";
import RAFPermissionRender from "../RAFComponents/helpers/PermissionHelper";
import {
    onLookupLinkClicked
} from "../RAFComponents/helpers/RAFMenuHelper";
import {
    ConvertToPascal,
    IsNotNullOrWhiteSpace,
    IsNullOrWhiteSpace,
    convertUTCDateToLocalTimezone,
    isArray,
    isNotEmptyArray,
    isNotNullAndUndefined,
    isNullOrUndefined
} from "../RAFComponents/helpers/utils";
import {
    QueryAttributeJM,
    ValueJson,
} from "../RAFComponents/models/Common/QueryAttributeJM";
import {
    RAFDataType,
    RAFUIType,
} from "../RAFComponents/models/Common/RAFDataType";
import { LookUpRow } from "../RAFComponents/models/CompositeTypes/LookUpRow";
import {
    BrowserIsDevice,
    RAFButtonConstant
} from "../constants/Common/Constants";


export const defaultDropdownGridTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    let fieldValue = data[data.column.field];

    if (isNotNullAndUndefined(fieldValue)) {
        //let currentChoiceList = item && item.ValueJson && item.ValueJson.find(x => x.Name === fieldValue);
        if (item.IsMultiSelect === true) {
            let objectValue: string[] = fieldValue;
            let retVal: string[] = [];
            if (isArray(objectValue)) {
                objectValue.forEach((objVal) => {
                    let obj =
                        item.ValueJson &&
                        item.ValueJson.find(
                            (x) => x.DisplayName === objVal || x.Name === objVal
                        );
                    retVal.push(obj && obj.DisplayName);
                });
                fieldValue = isNotNullAndUndefined(retVal) ? retVal.join(", ") : "NA";
            }


            return (
                <div>
                    <div className={"custom_key_value_outter"}>
                        <div className="custom_key_value e-badge">
                            <span> {fieldValue} </span>
                        </div>
                        {/* {showEdit === true && (
                  <div className="gridEditBtn noRowclick">
                    <ButtonComponent
                      type="button"
                      className="e-flat btndefault-icon e-editbutton"
                      iconCss="fal fa-pencil"
                    />
                  </div>
                )} */}
                    </div>
                </div>
            );
        }
        else if (
            IsNotNullOrWhiteSpace(item.UIType) &&
            item.UIType !== RAFUIType.ToggleButton &&
            item.UIType !== RAFUIType.SimpleDropdown
        ) {
            let currentChoiceList = isNotEmptyArray(item.ValueJson)
                ? item.ValueJson.find(
                    (x) => x.DisplayName === fieldValue || x.Name === fieldValue
                )
                : null;

            if (isNullOrUndefined(currentChoiceList)) {
                let defaultValue: ValueJson = {
                    ColorCode: "#8e24aa",
                    DisplayName: fieldValue,
                    Id: 99,
                    Name: fieldValue,
                };
                currentChoiceList = defaultValue;
            }

            if (isNotNullAndUndefined(currentChoiceList)) {
                return (
                    <div>
                        <div className={"custom_key_value_outter"}>
                            <div
                                style={{
                                    background: currentChoiceList.ColorCode,
                                    color: IsNotNullOrWhiteSpace(currentChoiceList.ColorCode)
                                        ? "white"
                                        : null,
                                }}
                                className="custom_key_value e-badge"
                            >
                                <span> {currentChoiceList.DisplayName} </span>
                            </div>
                        </div>
                    </div>
                );
            }
        }
        else {
            let currentChoiceList = isNotEmptyArray(item.ValueJson)
                ? item.ValueJson.find(
                    (x) => x.DisplayName === fieldValue || x.Name === fieldValue
                )
                : null;
            if (isNotNullAndUndefined(currentChoiceList)) {
                return <span> {currentChoiceList.DisplayName} </span>;
            } else {
                return <span> {fieldValue} </span>;
            }
        }
    }

    return;
};

export const firstColumnGridTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    let fieldValue = !IsNullOrWhiteSpace(data[data.column.field])
        ? data[data.column.field].toString()
        : "NA";

    if (isNotNullAndUndefined(item)) {
        if (item.DataType === RAFDataType.Dropdown) {
            if (isNotNullAndUndefined(fieldValue) && fieldValue) {
                if (item.IsMultiSelect === true) {
                    let objectValue: string[] = fieldValue;
                    let retVal: string[] = [];
                    if (isArray(objectValue)) {
                        objectValue.forEach((objVal) => {
                            let obj =
                                item.ValueJson &&
                                item.ValueJson.find(
                                    (x) => x.DisplayName === objVal || x.Name === objVal
                                );
                            retVal.push(obj && obj.DisplayName);
                        });
                        fieldValue = isNotNullAndUndefined(retVal)
                            ? retVal.join(", ")
                            : "NA";
                    }
                } else {
                    let selectedValueJson = item.ValueJson;
                    if (isNotNullAndUndefined(selectedValueJson)) {
                        let selectedItem = selectedValueJson.find(
                            (x) => x.Name === fieldValue || x.DisplayName === fieldValue
                        );
                        fieldValue = isNotNullAndUndefined(selectedItem)
                            ? selectedItem.DisplayName
                            : "NA";
                    }
                }
            }
        }
        if (item.DataType === RAFDataType.Boolean) {
            if (
                fieldValue === true ||
                fieldValue === "true" ||
                fieldValue === "True"
            ) {
                fieldValue = "Yes";
            } else {
                fieldValue = "No";
            }
        }
        if (item.DataType === RAFDataType.Date) {
            fieldValue = moment(fieldValue).format("DD/MM/YYYY");
        }
    }

    let initial = fieldValue && fieldValue.charAt(0).toUpperCase();
    let avatarClass = "avatar-text-circle";
    /*if (typeName === 'contact' || typeName === 'account') {
                          avatarClass = "avatar-text-circle avatar-initial-palettes-" + initial
                      }
                      else if (typeName === 'deal' || typeName === 'ticket') {
                          avatarClass = "avatar-text-square avatar-initial-palettes-" + initial
                      }*/
    if (isNotNullAndUndefined(moduleName)) {
        if (moduleName === "contact") {
            avatarClass = "avatar-text-circle contact-avatar";
        } else if (moduleName === "account") {
            avatarClass = "avatar-text-circle company-avatar";
        } else if (moduleName === "deal") {
            avatarClass = "avatar-text-square deal-avatar";
        } else if (moduleName === "ticket") {
            avatarClass = "avatar-text-square ticket-avatar";
        } else if (moduleName === "email") {
            avatarClass = "avatar-text-circle email-avatar";
        } else if (moduleName === "task") {
            avatarClass = "avatar-text-square task-avatar";
        } else if (moduleName === "user") {
            avatarClass = "avatar-text-square user-avatar";
        } else if (moduleName === "contract") {
            avatarClass = "avatar-text-square contract-avatar";
        } else if (moduleName === "template") {
            avatarClass = "avatar-text-square template-avatar";
        } else if (moduleName === "form_library") {
            avatarClass = "avatar-text-square form-library-avatar";
        } else if (moduleName === "team") {
            avatarClass = "avatar-text-square team-avatar";
        } else if (moduleName === "workspace") {
            avatarClass = "avatar-text-square default-avatar";
        } else if (moduleName === "role") {
            avatarClass = "avatar-text-square role-avatar";
        } else if (isNotNullAndUndefined(moduleName) && moduleName === "view") {
            avatarClass = "avatar-text-square view-avatar";
        }
    } else {
        avatarClass = "avatar-text-circle avatar-initial-palettes-" + initial;
    }
    let entityName = ConvertToPascal(moduleName).replace(/\s/g, "");

    // if (moduleName === RAFEntityName.ContentLibrary) {
    //   return documentDisplayNameTemplate(
    //     moduleName,
    //     allowEditing,
    //     showEdit,
    //     item,
    //     isDynamic,
    //     data,
    //     navigate
    //   );
    // } else {
    if (BrowserIsDevice) {
        return (
            <div className="gridDefault d-flex align-items-center justify-content-start">
                <div className="ecllipseFirstLine text-wrap word-break-all">
                    <span title={fieldValue}>{fieldValue}</span>
                </div>
                {showEdit === true && allowEditing === true && (
                    <RAFPermissionRender permissionName={entityName + "::Update"}>
                        <div className="gridEditBtn">
                            <RAFButtonComponent
                                action={RAFButtonConstant.Edit}
                                iconBtn
                                className="btndefault-icon e-editbutton"
                            />
                        </div>
                    </RAFPermissionRender>
                )}
            </div>
        );
    }
    return (
        <div className="gridDefault d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center w-100">
                {
                    //showEdit === true && allowEditing === true && hasEditPermission === true ? <div>
                    // showEdit === true && allowEditing === true ? (
                    //   <div>
                    //     <span
                    //       className={
                    //         fieldValue !== null && fieldValue !== undefined
                    //           ? avatarClass + " hover-hide-td-child-div-flex"
                    //           : "hover-hide-td-child-div-flex"
                    //       }
                    //       style={{
                    //         width: "30px",
                    //         height: "30px",
                    //         minWidth: "30px",
                    //         maxWidth: "30px",
                    //         fontSize: "12px",
                    //         fontWeight: 600,
                    //         marginRight: "8px",
                    //       }}
                    //     >
                    //       {initial}
                    //     </span>
                    //     <span
                    //       className={
                    //         fieldValue !== null && fieldValue !== undefined
                    //           ? avatarClass +
                    //           " e-editbutton hover-show-td-child-div-flex"
                    //           : "e-editbutton hover-show-td-child-div-flex"
                    //       }
                    //       style={{
                    //         width: "30px",
                    //         height: "30px",
                    //         minWidth: "30px",
                    //         maxWidth: "30px",
                    //         fontSize: "12px",
                    //         fontWeight: 600,
                    //         marginRight: "8px",
                    //       }}
                    //     >
                    //       <span
                    //         className="fal fa-pencil"
                    //         style={{ fontSize: "12px" }}
                    //       ></span>
                    //     </span>
                    //   </div>
                    // ) : (
                    <span
                        className={
                            fieldValue !== null && fieldValue !== undefined ? avatarClass : ""
                        }
                        style={{
                            width: "30px",
                            height: "30px",
                            minWidth: "30px",
                            maxWidth: "30px",
                            fontSize: "12px",
                            fontWeight: 600,
                            marginRight: "8px",
                        }}
                    >
                        {initial}
                    </span>
                    // )
                }

                <div className="ecllipseFirstLine text-wrap word-break-all">
                    <span title={fieldValue}>{fieldValue}</span>
                </div>
            </div>
            {showEdit === true && allowEditing === true && (
                <RAFPermissionRender permissionName={entityName + "::Update"}>
                    <div className="gridEditBtn">
                        <RAFButtonComponent
                            action={RAFButtonConstant.Edit}
                            iconBtn
                            className="btndefault-icon e-editbutton"
                        />
                    </div>
                </RAFPermissionRender>
            )}
        </div>
    );
    // }
};

export const isBooleanGridTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldName = data.column.field;
    const fieldValue = data[fieldName];
    let displayText = "No";
    if (isNotNullAndUndefined(fieldValue) && fieldValue) {
        displayText = "Yes";
    } else {
        displayText = "No";
    }
    if (isNotNullAndUndefined(displayText)) {
        return (
            <div className="gridDefault d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center w-100">
                    <div className="gridTempleteTextOuter">
                        <span>{displayText}</span>
                    </div>
                </div>
            </div>
        );
    }
    return;
};

export const lookupMultiSelectGridTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldName = data.column.field;
    const fieldValue = data[fieldName];
    // if (isNotNullAndUndefined(fieldValue) && fieldValue) {
    //   const jsonValue: any[] = JSON.parse(fieldValue);
    //   if (isNotNullAndUndefined(jsonValue)) {
    //     // displayText = jsonValue.map((e) => e.value).join(",");
    //   }
    // }
    let lookUpValue =
        isNotNullAndUndefined(fieldValue) &&
            isArray(fieldValue) &&
            fieldValue.length > 0
            ? fieldValue.map((x) => x.Value)
            : null;
    let displayText = isNotNullAndUndefined(lookUpValue)
        ? lookUpValue.toString()
        : null;
    if (isNotNullAndUndefined(displayText)) {
        return (
            <div className="gridDefault d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center noRowclick w-100">
                    <div className="gridTempleteTextOuter">
                        <span className="ms-2" style={{ cursor: "pointer" }}>
                            {displayText}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
    return;
};

export const tagsGridTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    let TagsListJson = "TagsListJson";
    const fieldValueTag = data[TagsListJson];
    if (isNotEmptyArray(fieldValueTag)) {
        return (
            <div className="raftags">
                <ChipListComponent id="chip-avatar" cssClass="e-outline">
                    <ChipsDirective>
                        {fieldValueTag &&
                            fieldValueTag.map((tag, index) => (
                                <ChipDirective key={index} text={tag}></ChipDirective>
                            ))}
                    </ChipsDirective>
                </ChipListComponent>
            </div>
        );
    }
    return;
};

export const descriptionGridTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldValue = data[data.column.field];
    if (isNotNullAndUndefined(fieldValue)) {
        return (
            <div className="gridDefault d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span
                        className="description-text regular"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(fieldValue),
                        }}
                    ></span>
                </div>
                {showEdit === true && allowEditing === true && (
                    <div className="gridEditBtn">
                        <ButtonComponent
                            type="button"
                            className="e-flat btndefault-icon e-editbutton"
                            iconCss="fal fa-pencil"
                        />
                    </div>
                )}
            </div>
        );
    }
    return;
};

export const dateTimeGridTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldValue = data[data.column.field];
    if (isNotNullAndUndefined(fieldValue)) {
        let localDate = convertUTCDateToLocalTimezone(fieldValue);
        return (
            <div className="gridDefault d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="">
                        {moment(localDate).format("DD/MM/YYYY hh:mm A")}
                    </span>
                </div>
                {showEdit === true && allowEditing === true && (
                    <div className="gridEditBtn">
                        <ButtonComponent
                            type="button"
                            className="e-flat btndefault-icon e-editbutton"
                            iconCss="fal fa-pencil"
                        />
                    </div>
                )}
            </div>
        );
    }
    return;
};

export const dateGridTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldValue = data[data.column.field];
    if (isNotNullAndUndefined(fieldValue)) {
        let localDate = convertUTCDateToLocalTimezone(fieldValue);
        return (
            <div className="gridDefault d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="">{moment(localDate).format("DD/MM/YYYY")}</span>
                </div>
                {showEdit === true && allowEditing === true && (
                    <div className="gridEditBtn">
                        <ButtonComponent
                            type="button"
                            className="e-flat btndefault-icon e-editbutton"
                            iconCss="fal fa-pencil"
                        />
                    </div>
                )}
            </div>
        );
    }
    return;
};

export const lookupGridTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldName = data.column.field;
    const fieldValue = data[fieldName];

    let iconName;
    let avatarClassName;
    switch (item.RelatedEntities) {
        case "contact":
            iconName = "address-card";
            avatarClassName = "contact-avatar";
            break;
        case "account":
            iconName = "building";
            avatarClassName = "company-avatar";
            break;
        default:
            break;
    }

    if (isNotNullAndUndefined(fieldValue)) {
        let valueUID = data[fieldName + "UID"];

        return (
            <div
                className={
                    isNotNullAndUndefined(valueUID)
                        ? "gridDefault d-flex align-items-center justify-content-between raf-account-relatedto noRowclick"
                        : "gridDefault d-flex align-items-center justify-content-between raf-account-relatedto"
                }
                onClick={() =>
                    onLookupLinkClicked(
                        item.RelatedEntities,
                        valueUID,
                        isDynamic,
                        navigate
                    )
                }
            >
                <div className="d-flex align-items-center w-100 overflow-auto">
                    {!Browser.isDevice && (<span
                        className={"avatar-text-square " + avatarClassName}
                        style={{
                            width: "24px",
                            height: "24px",
                            minWidth: "24px",
                            maxWidth: "24px",
                            fontSize: "12px",
                        }}
                    >
                        <span className={"fa fa-" + iconName}></span>
                    </span>
                    )}
                    <div className="gridTempleteTextOuter">
                        <span
                            className="ms-2"
                            style={{ color: "#0078d6", cursor: "pointer" }}
                        >
                            {fieldValue}
                        </span>
                    </div>
                </div>
                <div
                    className="gridEditBtn noRowclick"
                    onClick={(e) => e.stopPropagation()}
                >
                    {!Browser.isDevice && (
                        <ButtonComponent
                            type="button"
                            className="grid-square-btn"
                            iconCss="far fa-arrow-up-right-from-square"
                            onClick={() =>
                                onLookupLinkClicked(item.RelatedEntities, valueUID, isDynamic)
                            }
                        />
                    )}
                    {/* {showEdit === true && (
                  <ButtonComponent
                    type="button"
                    className="e-flat btndefault-icon e-editbutton unset-custom-button-md"
                    iconCss="fal fa-pencil"
                  />
                )} */}
                </div>
            </div>
        );
    }
    return;
};

export const updatedDateTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldName = data.column.field;
    const fieldValue = data[fieldName];
    const createdBy = data["CreatedBy"];
    const createdDate = data["CreatedDate"];
    const modifiedDate = data["ModifiedDate"];
    const modifiedBy = data["ModifiedBy"];
    const lastActivityDate = data["LastActivityDate"];

    if (isNotNullAndUndefined(fieldValue)) {
        return (
            <div
                className="gridDefault d-flex align-items-center justify-content-between"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="recordInfomsg">
                    <RAFRecordInfo
                        createdBy={createdBy}
                        createdDate={createdDate}
                        modifiedDate={modifiedDate}
                        modifiedBy={modifiedBy}
                        lastActivityDate={lastActivityDate}
                        dropdownBtnClassName="p-0 custom-button-sm"
                        content=""
                    ></RAFRecordInfo>
                </div>
            </div>
        );
    }
    return;
};

export const riskmatrixJsonTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldValue = data[data.column.field];
    if (isNotNullAndUndefined(fieldValue)) {
        const riskmatrixValue: RiskScoreTransValueRow = JSON.parse(fieldValue);
        const levelBGColor =
            isNotNullAndUndefined(riskmatrixValue) ?
                riskmatrixValue.Color
                : null;

        const riskScore = isNotNullAndUndefined(riskmatrixValue)
            ? riskmatrixValue.Score
            : null;

        const levelName = isNotNullAndUndefined(riskmatrixValue)
            ? riskmatrixValue.LevelName
            : null;

        return (
            <div className="gridDefault d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span
                        style={{
                            backgroundColor: isNotNullAndUndefined(levelBGColor) ? levelBGColor : "#cccccc",
                            color: "#000",
                        }}
                    >
                        {IsNotNullOrWhiteSpace(riskScore) ? `${riskScore} - ${levelName}` : null}
                    </span>
                </div>
                {showEdit === true && allowEditing === true && (
                    <div className="gridEditBtn">
                        <ButtonComponent
                            type="button"
                            className="e-flat btndefault-icon e-editbutton"
                            iconCss="e-icons e-edit"
                        />
                    </div>
                )}
            </div>
        );
    }
    return;
};

export const bodypartpickerTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldValue: LookUpRow[] = data[data.column.field];
    if (isNotEmptyArray(fieldValue)) {
        const convertedFieldValue = fieldValue.map(item => item.Value).join('; ');
        return (
            <div className="gridDefault d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="ecllipseFirstLine text-wrap word-break-all">
                        <span>
                            {convertedFieldValue}
                        </span>
                    </div>
                </div>
                {showEdit === true && allowEditing === true && (
                    <div className="gridEditBtn">
                        <ButtonComponent
                            type="button"
                            className="e-flat btndefault-icon e-editbutton"
                            iconCss="e-icons e-edit"
                        />
                    </div>
                )}
            </div>
        );
    }
    return;
};
