export enum PhoneType {
	Home = "Home",
	Work = "Work",
	Personal = "Personal",
	TollFree = "Toll-Free",
	Fax = "Fax"
}
export class PhoneRow {
	id?: number;
	PhoneNumber?: string;
	PhoneExtn?: string;
	PhoneType?: string;
}
