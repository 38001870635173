import { RAFEntityBase } from './../../../RAFComponents/models/Common/RAFEntityBase'
import {  } from '../../../RAFComponents/helpers/utils';
import { ILookupRow } from '../../../RAFComponents/models/CompositeTypes/ILookupRow';
import { propertyOf } from '../../../RAFComponents/helpers/utils';
export class CityRow extends RAFEntityBase implements ILookupRow {
    
    UID?: string;
    CityID?: number;
    City?: string;

    getIdField(): string {
        return propertyOf<CityRow>("UID");
    }
    getNameField(): string {
        return propertyOf<CityRow>("City");
    }
    getListUrl(): string {
        return "City/List";
    }
    isOptionCreatable(): boolean {
        return false;
    }
    getLookupUrl(): string {
        return "City/Lookup";
    }
}