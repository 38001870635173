import { PropsWithChildren, useContext, useEffect } from "react";
import { Address, Country, WidgetInput } from "react-addressfinder";
import { FormRenderProps } from "react-final-form";
import RAFTextBox from "../../../RAFComponents/Inputs/RAFTextBox";
import {
  RAFFormContext
} from "../../../RAFComponents/Inputs/RFFUtils";
import {
  Guid,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";

interface IProps {
  field1?: string;
  field2?: string;
  field3?: string;
  field4?: string;
  field5?: string;
  field6?: string;
}

function RAFAddress<T>({
  field1,
  field2,
  field3,
  field4,
  field5,
  field6,
}: PropsWithChildren<IProps>) {
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  useEffect(() => {
    const initTribute = () => {
      const script = document.createElement("script");
      script.src = "https://api.addressfinder.io/assets/v3/widget.js";
      script.async = true;
      document.body.appendChild(script);
    };
    initTribute();
    if (
      isNotNullAndUndefined(rafFormContextValue) &&
      isNotNullAndUndefined(rafFormContextValue.values)
    ) {
      let addressLine1: HTMLInputElement = document.querySelector(
        "#addressLine1" + indexVal
      );
      if (
        isNotNullAndUndefined(addressLine1) &&
        isNotNullAndUndefined(rafFormContextValue.values[field1])
      ) {
        setTimeout(() => {
          addressLine1.value = rafFormContextValue.values[field1];
          if (
            isNotNullAndUndefined(rafFormContextValue) &&
            isNotNullAndUndefined(rafFormContextValue.form) &&
            isNotNullAndUndefined(rafFormContextValue.form.mutators)
          ) {
            rafFormContextValue.form.mutators.setValue(
              field1,
              addressLine1.value
            );
          }
        }, 500);
      }
    }
  }, []);

  let indexVal: string = Guid.newGuid();

  const onInputChange = () => {
    let addressLine1: HTMLInputElement = document.querySelector(
      "#addressLine1" + indexVal
    );
    let clearBtn = document.querySelector("#clearBtn" + indexVal);
    if (
      isNotNullAndUndefined(clearBtn) &&
      isNotNullAndUndefined(addressLine1)
    ) {
      if (addressLine1.value.length > 0) {
        clearBtn.classList.add("active");
      } else {
        clearBtn.classList.remove("active");
      }
    }
  };

  const onClearInput = () => {
    let clearBtn = document.querySelector("#clearBtn" + indexVal);
    let addressLine1: HTMLInputElement = document.querySelector(
      "#addressLine1" + indexVal
    );
    setTimeout(() => {
      if (isNotNullAndUndefined(addressLine1)) {
        addressLine1.value = "";
        addressLine1.innerHTML = "";
        if (
          isNotNullAndUndefined(rafFormContextValue) &&
          isNotNullAndUndefined(rafFormContextValue.form) &&
          isNotNullAndUndefined(rafFormContextValue.form.mutators)
        ) {
          rafFormContextValue.form.mutators.setValue(field1, null);
        }
      }
    }, 500);
    if (isNotNullAndUndefined(clearBtn)) {
      clearBtn.classList.remove("active");
    }
  };

  const onChange = (e) => {
    //console.log('onChange', e);
    setTimeout(() => {
      const elementaf_footer = document.querySelector('.addressList:last-child li:last-child') as HTMLElement;
      if (isNotNullAndUndefined(elementaf_footer)) {
        elementaf_footer.style.display = 'none';
      }
    }, 1000);

  };


  const onBlurChange = (e) => {
    if (
      isNotNullAndUndefined(rafFormContextValue) &&
      isNotNullAndUndefined(rafFormContextValue.form) &&
      isNotNullAndUndefined(rafFormContextValue.form.mutators)
    ) {
      rafFormContextValue.form.mutators.setValue(field1, e.target.value);
    }
  };

  //let createEditForm: HTMLElement = document.querySelector('.createEditForm');

  //createEditForm.onclick = function () {
  //    let clearBtn = document.querySelector('#clearBtn');
  //    if (isNotNullAndUndefined(clearBtn)) {
  //        clearBtn.classList.remove('active');
  //    }
  //}

  const onAddressSelected = (address: Address) => {
    let addressLine1: HTMLInputElement = document.querySelector(
      "#addressLine1" + indexVal
    );
    if (
      isNotNullAndUndefined(rafFormContextValue) &&
      isNotNullAndUndefined(rafFormContextValue.form) &&
      isNotNullAndUndefined(rafFormContextValue.form.mutators)
    ) {
      setTimeout(() => {
        rafFormContextValue.form.mutators.setValue(field1, address.line1);
        rafFormContextValue.form.mutators.setValue(field2, address.line2);
        rafFormContextValue.form.mutators.setValue(field3, address.suburb);
        rafFormContextValue.form.mutators.setValue(field4, address.state);
        rafFormContextValue.form.mutators.setValue(field5, address.country);
        rafFormContextValue.form.mutators.setValue(field6, address.postcode);
        if (isNotNullAndUndefined(addressLine1)) {
          addressLine1.value = address.line1;
        }
      }, 500);
    }
  };

  return (
    <div className="app-container p-0">
      <div className="list">
        <div>
          <div className="mb-2 addressSection">
            <div className="row g-2">
              <div className="col-md-12">
                <div className="wrapper">
                  <WidgetInput
                    type="text"
                    autoComplete="off"
                    placeholder="Address line1"
                    addressFinderKey={"H89WGJBPFEUKRCVXQYND"}
                    country={Country.AU}
                    inputClassName="e-control e-textbox e-lib e-input"
                    id={"addressLine1" + indexVal}
                    listClassName={"addressList"}
                    onSelected={(fullAddress: string, address: Address) => {
                      onAddressSelected(address);
                    }}
                    onChange={onChange}
                    onFocus={onInputChange}
                    onBlur={onBlurChange}
                  />
                  <span
                    id={"clearBtn" + indexVal}
                    className="clearBtn"
                    onClick={onClearInput}
                  >
                    <i className="fal fa-xmark"></i>
                    {/* &times; */}
                  </span>
                </div>
                <span className="text-input-wrapper"></span>
              </div>
              <div className="col-md-12 hidden">
                <RAFTextBox
                  field={field2}
                  showLabel={false}
                  placeholder="Address line2"
                />
              </div>
              <div className="col-md-6">
                <RAFTextBox
                  field={field3}
                  showLabel={false}
                  placeholder="Suburb"
                />
              </div>
              <div className="col-md-6">
                <RAFTextBox
                  field={field4}
                  showLabel={false}
                  placeholder="State"
                />
              </div>
              <div className="col-md-6">
                <RAFTextBox
                  field={field5}
                  showLabel={false}
                  placeholder="Country"
                />
              </div>
              <div className="col-md-6">
                <RAFTextBox
                  field={field6}
                  showLabel={false}
                  placeholder="Postal code"
                />
              </div>
            </div>
            {/*<div className="wrapper">
                            <WidgetInput
                                type="text"
                                autoComplete="off"
                                placeholder="Address line1"
                                addressFinderKey={'H89WGJBPFEUKRCVXQYND'}
                                country={Country.AU}
                                inputClassName="e-control e-textbox e-lib e-input"
                                id={'addressLine1' + indexVal}
                                listClassName={'addressList'}
                                onSelected={(fullAddress: string, address: Address) => {
                                    onAddressSelected(address);
                                }}
                                onChange={onInputChange} onFocus={onInputChange}
                            />
                            <span id={"clearBtn"+ indexVal} className="clearBtn" onClick={onClearInput}>&times;</span>
                        </div>
                        <span className="text-input-wrapper">

                        </span>
                        <RAFPanel layout={RAFLayout.OneColumnLayout} className="me-0 hidden">
                            <RAFTextBox field={field2} showLabel={false} placeholder="Address line2" />
                        </RAFPanel>
                        <RAFPanel layout={RAFLayout.TwoColumnLayout} className="me-0">
                            <RAFTextBox field={field3} showLabel={false} placeholder="Suburb" />
                            <RAFTextBox field={field4} showLabel={false} placeholder="State" />
                        </RAFPanel>
                        <RAFPanel layout={RAFLayout.TwoColumnLayout} className="me-0">
                            <RAFTextBox field={field5} showLabel={false} placeholder="Country" />
                            <RAFTextBox field={field6} showLabel={false} placeholder="Postal code" />
                        </RAFPanel>*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RAFAddress;
