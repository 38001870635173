import { RAFEntityBase } from './../../../RAFComponents/models/Common/RAFEntityBase'
import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { ILookupRow } from '../../../RAFComponents/models/CompositeTypes/ILookupRow';
export class StateRow extends RAFEntityBase implements ILookupRow{
    UID?: string;
    StateID?: number;
    State?: string;

    getIdField(): string {
        return propertyOf<StateRow>("UID");
    }
    getNameField(): string {
        return propertyOf<StateRow>("State");
    }
    getListUrl(): string {
        return "State/List";
    }
    isOptionCreatable(): boolean {
        return false;
    }
    getLookupUrl(): string {
        return "State/Lookup";
    }
}