//export enum AddressType {
//	Home = "Home",
//	Billing = "Billing",
//	Shipping = "Shipping",
//	Others = "Others",
//}

export enum AddressType {
	ResidentialAddress = "Residential",
	PostalAddress = "Postal",
}

export class AddressRow {
	id?: number;
	//address_type?: AddressType;
	//line1?: string;
	//line2?: string;
	//city_uid?: string;
	//city_name?: string;
	//state_uid?: string;
	//state_name?: string;
	//country_uid?: string;
	//country_name?: string;
	//postal_code?: number;


	AddressType?: string;
	AddressLine1?: string;
	AddressLine2?: string;
	//City?: CityRow;
	//State?: StateRow;
	//Country?: CountryRow;
	City?: string;
	CityUID?: string;
	State?: string;
	StateUID?: string;
	Country?: string;
	CountryUID?: string;
	PostalCode?: string;

}
