import { RAFEntityBase } from './../../../RAFComponents/models/Common/RAFEntityBase'
import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { ILookupGroupRow } from '../../../RAFComponents/models/CompositeTypes/ILookupRow';
export class CountryRow extends RAFEntityBase implements ILookupGroupRow {
    UID?: string;
    CountryID?: number;
    Country?: string;

    getIdField(): string {
        return propertyOf<CountryRow>("UID");
    }
    getNameField(): string {
        return propertyOf<CountryRow>("Country");
    }
    getListUrl(): string {
        return "Country/List";
    }
    isOptionCreatable(): boolean {
        return false;
    }
    getLookupUrl(): string {
        return "Country/Lookup";
    }
    getGroupField(): string {
        return propertyOf<CountryRow>("Country");
    }
}