import { PropsWithChildren } from 'react';
import RAFAutoCompleteMUI from '../../../RAFComponents/Inputs/RAFAutoCompleteMUI';
import RAFFieldLabel from '../../../RAFComponents/Inputs/RAFFieldLabel';
import { RAFDefaultFieldClassName, RAFDefaultFieldProps, RAFLookupFieldProps } from '../../../RAFComponents/Inputs/RFFUtils';

function ACAutoCompleteMUI<T>(
    { field, label, width, type, onChanged,
        SearchCreateOptionMode, showCreateButton,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
        disabled = RAFDefaultFieldProps.disabled,
        showClearButton = RAFDefaultFieldProps.showClearButton,
        validate = RAFDefaultFieldProps.validate,
        ...props }: PropsWithChildren<RAFLookupFieldProps<T>>,
) {
    return (
        <div>
            <div className={`${RAFDefaultFieldClassName.rowClassName}`} id={"rafdiv" + field.toString()} >
                {showLabel && showLabel === true &&
                    <RAFFieldLabel
                        field={field}
                        label={label}
                        required={required}
                        labelClassName={props.labelClassName}
                    ></RAFFieldLabel>
                }
                <div className={props.inputFieldClassName ? props.inputFieldClassName + " d-flex" : "col-12 d-flex"}>
                    <div className="w-100">
                        <RAFAutoCompleteMUI<T>
                            field={field} label={label} required={required} showLabel={false} type={type} SearchCreateOptionMode={SearchCreateOptionMode} showCreateButton={showCreateButton} width={width && width !== null ? width : '100%'} disabled={disabled} validate={validate} onChanged={onChanged} createFormInitialValues={props.createFormInitialValues} {...props} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ACAutoCompleteMUI;