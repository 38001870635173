import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { PropsWithChildren, useContext } from 'react';
import { FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFEmailTextBox from "../../../RAFComponents/Inputs/RAFEmailTextBox";
import RAFFieldLabel from "../../../RAFComponents/Inputs/RAFFieldLabel";
import RAFInputGroup from "../../../RAFComponents/Inputs/RAFInputGroup";
import { RAFDefaultFieldProps, RAFFieldProps, RAFFormContext } from "../../../RAFComponents/Inputs/RFFUtils";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { EmailType } from "./EmailRow";

interface IProps {
    showAdd?: boolean;
    checkUnique?: boolean;
    dropDownItems?: any[];
}

function RAFEmail1<T>(
    { field, label,
        dropDownItems, labelClassName, description,
        showAdd = true,
        checkUnique = true,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
    }: PropsWithChildren<RAFFieldProps<T> & IProps>,
) {
    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

    const selectedEmailListValues = checkUnique === true && isNotNullAndUndefined(rafFormContextValue) &&
        isNotNullAndUndefined(rafFormContextValue.values) && isNotNullAndUndefined(rafFormContextValue.values[field.toString()]) ? rafFormContextValue.values[field.toString()] : [];

    const disableEmailDropDownItemsValue = [];
    selectedEmailListValues && selectedEmailListValues.forEach(item => {
        if (isNotNullAndUndefined(item.EmailType))
            disableEmailDropDownItemsValue.push({ label: item.EmailType, value: item.EmailType });
    });

    const emailDropDownItems = isNotNullAndUndefined(dropDownItems) && dropDownItems.length > 0 ? dropDownItems : [{ label: "Personal", value: EmailType.Personal },
    { label: "Work", value: EmailType.Work }];


    const onAddClick = (field: keyof T | string) => {
        if (isNotNullAndUndefined(rafFormContextValue) && isNotNullAndUndefined(rafFormContextValue.form) && isNotNullAndUndefined(rafFormContextValue.form.mutators)) {
            rafFormContextValue.form.mutators.push(field.toString(), { EmailType: null, EmailId: null });
        }
    };

    const onDeleteClick = (fields, index, required1) => {
        if (isNotNullAndUndefined(required1) && required1 === true) {
            if (isNotNullAndUndefined(fields) && fields.length > 1) {
                fields.remove(index);
            }
            else {
                showWarningToast('Atleast one email is required');
            }
        }
        else {
            fields.remove(index);
        }
    };

    const onChangeEmailType = (value, label, index, fieldName) => {
        if (isNotNullAndUndefined(rafFormContextValue) && isNotNullAndUndefined(rafFormContextValue.form) && isNotNullAndUndefined(rafFormContextValue.form.mutators)) {
            rafFormContextValue.form.mutators.setValue(fieldName, null);
        }
    };

    return (
        <div className="app-container p-0" id={"rafdiv" + field.toString()} >
            <div className="d-flex justify-content-between">
                {showLabel && showLabel === true &&
                    <RAFFieldLabel
                        field={field}
                        label={label}
                        required={required}
                        labelClassName={labelClassName}
                        description={description}
                    ></RAFFieldLabel>
                }
            </div>
            <div className="list mb-3">
                <div className="row g-2">
                    <FieldArray name={field.toString()}>
                        {({ fields }) =>
                            fields.map((name, index) => (
                                <div className="col-12" key={name}>
                                    <div className="row g-2 align-items-center phoneSection">
                                        <div className="col">
                                            <RAFDropdownCC field={`${name}.EmailType`}
                                                formGroupClassName="m-0" showLabel={false} placeholder="Select Type"
                                                {...checkUnique === true ? { disableItems: disableEmailDropDownItemsValue } : {}}
                                                onChanged={(label, value) => { if (checkUnique === true) onChangeEmailType(label, value, index, `${name}.EmailId`); }}
                                                showClearButton={false}
                                            >
                                                {emailDropDownItems.map((item, index) => {
                                                    return <RAFChoiceOption key={index} label={item.label} value={item.value} />;
                                                })
                                                }
                                                {/*<RAFChoiceOption label="Personal" value={EmailType.Personal} />
                                            <RAFChoiceOption label="Work" value={EmailType.Work} />*/}
                                            </RAFDropdownCC>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 inputFieldContent">
                                            <RAFInputGroup showLabel={false} label="" formGroupClassName="m-0" >
                                                <span className="e-input-group-icon fas fa-envelope"></span>
                                                <div className="e-input-in-wrap">
                                                    <RAFEmailTextBox field={`${name}.EmailId`} required={required} showLabel={false} placeholder="example@domain.com" />
                                                </div>
                                            </RAFInputGroup>
                                        </div>
                                        <div className="col-auto inputFieldDeleteButton">
                                            <div>
                                                <ButtonComponent type="button" onClick={() => onDeleteClick(fields, index, required)} title="Click to remove" className="removeItem">
                                                    <span className="fas fa-trash"></span>
                                                </ButtonComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </FieldArray>
                    {showAdd && ((checkUnique === true && emailDropDownItems.length !== disableEmailDropDownItemsValue.length) || checkUnique === false) &&
                        <div className="col-12">
                            <button type="button" className="link-button" onClick={() => onAddClick(field)}> Add Email </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default RAFEmail1;

