const CONTACT_READ = "Contact::Read";
const CONTACT_CREATE = "Contact::Add";
const CONTACT_UPDATE = "Contact::Update";
const CONTACT_DELETE = "Contact::Delete";
const CONTACT_IMPORT = "Contact::Import";
const CONTACT_ADD_VIEW = "Contact::AddView";
const CONTACT_ManageLogin = "Contact::ManageLogin";

const ACCOUNT_READ = "Account::Read";
const ACCOUNT_CREATE = "Account::Add";
const ACCOUNT_UPDATE = "Account::Update";
const ACCOUNT_DELETE = "Account::Delete";
const ACCOUNT_IMPORT = "Account::Import";
const ACCOUNT_ADD_VIEW = "Account::AddView";
const ACCOUNT_MERGE = "Account::Merge";
const ACCOUNT_LinkXero = "Account::LinkXero";

const DEAL_READ = "Deal::Read";
const DEAL_CREATE = "Deal::Add";
const DEAL_UPDATE = "Deal::Update";
const DEAL_DELETE = "Deal::Delete";
const DEAL_IMPORT = "Deal::Import";
const DEAL_ADD_VIEW = "Deal::AddView";

const TICKET_READ = "Ticket::Read";
const TICKET_CREATE = "Ticket::Add";
const TICKET_UPDATE = "Ticket::Update";
const TICKET_DELETE = "Ticket::Delete";
const TICKET_IMPORT = "Ticket::Import";
const TICKET_ADD_VIEW = "Ticket::AddView";

export const ContactConstants = {
    contactRead: CONTACT_READ,
    ContactRead: CONTACT_READ,
    ContactCreate: CONTACT_CREATE,
    ContactUpdate: CONTACT_UPDATE,
    ContactDelete: CONTACT_DELETE,
    ContactImport: CONTACT_IMPORT,
    ContactAddView: CONTACT_ADD_VIEW,
    ContactManageLogin: CONTACT_ManageLogin
};

export const AccountConstants = {
    accountRead: ACCOUNT_READ,
    AccountRead: ACCOUNT_READ,
    AccountCreate: ACCOUNT_CREATE,
    AccountUpdate: ACCOUNT_UPDATE,
    AccountDelete: ACCOUNT_DELETE,
    AccountImport: ACCOUNT_IMPORT,
    AccountAddView: ACCOUNT_ADD_VIEW,
    AccountMerge: ACCOUNT_MERGE,
    AccountLinkXero: ACCOUNT_LinkXero
};

export const DealConstants = {
    dealRead: DEAL_READ,
    DealRead: DEAL_READ,
    DealCreate: DEAL_CREATE,
    DealUpdate: DEAL_UPDATE,
    DealDelete: DEAL_DELETE,
    DealImport: DEAL_IMPORT,
    DealAddView: DEAL_ADD_VIEW
};

export const TicketConstants = {
    ticketRead: TICKET_READ,
    TicketRead: TICKET_READ,
    TicketCreate: TICKET_CREATE,
    TicketUpdate: TICKET_UPDATE,
    TicketDelete: TICKET_DELETE,
    TicketImport: TICKET_IMPORT,
    TicketAddView: TICKET_ADD_VIEW
};
