export enum EmailType {
    Personal = "Personal",
    Work = "Work",
    Home = "Home"
}
export class EmailRow {
	id?: number;
	EmailId?: string;
	EmailType?: string;
    
}
