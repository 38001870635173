export class ACStorageKey {
    static showHide_modulename = '_showHide_';
    static task_DueDate = '_task_DueDate';
    static recentClickedMenu = '_recentClickedMenu';
}


export enum ACRAFDateType {
    Today = 'today',
    Tomorrow = 'tomorrow',
    DayAfterTomorrow = 'day after tomorrow',
    Next7Days = 'next7',
    Next15Days = 'next15',
    Next30Days = 'next30',
    Next45Days = 'next45',
    NextWeek = 'nextweek',
    NextMonth = 'nextmonth'
}

export enum ACRAFEmailActionType {
    ShowInputCC = 'ShowInputCC',
    Compose = 'Compose',
    Send = 'Send',
    Reply = 'Reply',
    ReplyAll = 'Reply All',
    Forward = 'Forward',
    SaveDraft = 'SaveDraft',
    Attach = 'Attach',
    Discard = 'Discard',
    Close = 'Close',
    Inbox = 'Inbox',
    Draft = 'Draft',
    Sent = 'Sent',
    Outbox = 'Outbox',
    Template = 'Template',
    Signature = 'Signature',
}

export enum ACViewLayoutType {
    TableView = 'Table',
    GroupView = 'Group',
    PivotView = 'Pivot',
    Reports = 'Reports',
    CrossTabView = 'Cross Tab View',
    Charts = 'Chart',
    ChartView = 'Charts Tab View'
}

export enum DashboardWidgetNames {
    TaskWidget = 'TaskWidget',
    FocusWidget = 'FocusWidget',
    FeedsWidget = 'FeedsWidget',
}

export class UserFeedAction {
    static Mentioned = "Mentioned";
    static Assigned = "Assigned";
    static Completed = "Completed";
}
