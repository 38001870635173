import { PropsWithChildren } from 'react';
import { RAFDefaultFieldProps, RAFFieldProps } from '../../../RAFComponents/Inputs/RFFUtils';
import { RAFAttributesContext } from '../../../RAFComponents/Providers/RAFAttributeRelatedListProvider';
import { GetFieldsDisplayName } from '../../../RAFComponents/helpers/AppHelper';
import { isNotNullAndUndefined } from '../../../RAFComponents/helpers/utils';
import RAFPhone1 from '../../shared/RAFPhone/RAFPhone1';
import { PhoneType } from '../RAFPhone/PhoneRow';

//const RAFTextBox = ({ field, label, required }) => {

interface IProps {
    showAdd?: boolean;
    checkUnique?: boolean;
    dropDownItems?: any[];
}

function ACPhone<T>(
    { field, label, width, showAdd, children, checkUnique, dropDownItems,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
    }: PropsWithChildren<RAFFieldProps<T> & IProps>,
) {

    const phoneDropDownItems = isNotNullAndUndefined(dropDownItems) && dropDownItems.length > 0 ? dropDownItems : [{ label: "Home", value: PhoneType.Home },
    { label: "Work", value: PhoneType.Work },
    { label: "Personal", value: PhoneType.Personal },
    { label: "Toll-Free", value: PhoneType.TollFree },
    { label: "Fax", value: PhoneType.Fax }];

    return (
        <div>
            <div className="row align-items-center gx-1 gx-md-2 gx-xl-3" id={"rafdiv" + field.toString()} >
                <div className="col-12 d-flex">
                    {showLabel && showLabel === true &&
                        <RAFAttributesContext.Consumer>
                            {({ queryAttributes, attributeRelatedList }) => {
                                return (
                                    <label
                                        className={required ? "form-label required" : "form-label"}
                                    >
                                        {
                                            GetFieldsDisplayName(
                                                queryAttributes,
                                                field.toString(),
                                                label, attributeRelatedList
                                            )}{" "}
                                    </label>
                                );
                            }}
                        </RAFAttributesContext.Consumer>
                    }
                </div>
                <div className="col-12 d-flex">
                    <div className="w-100">
                        <RAFPhone1<T> field={field} label={label} required={required} showLabel={false} dropDownItems={phoneDropDownItems} checkUnique={checkUnique} children={children} showAdd={showAdd} width={width && width !== null ? width : '100%'} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ACPhone;