import React, { PropsWithChildren, Reducer, useContext, useEffect, useReducer } from 'react';
import { FormRenderProps } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { msalInstance } from '..';
import { LoginUserContext } from '../RAFAuthentication/RAFLoginUserContextProvider';
import RAFChoiceOption from '../RAFComponents/Inputs/RAFChoiceOption';
import RAFDropdownCC from '../RAFComponents/Inputs/RAFDropdownCC';
import RAFForm from '../RAFComponents/Inputs/RAFForm';
import { setFormValue } from '../RAFComponents/Inputs/RFFUtils';
import CustomCardWidget from '../RAFComponents/Navigation/CustomCardWidget';
import RAFButtonComponent from '../RAFComponents/Navigation/RAFButtonComponent';
import RAFDeletedRecordState from '../RAFComponents/Navigation/RAFDeletedRecordState';
import { showWarningToast } from '../RAFComponents/Utility/RAFToastComponent';
import { getImpersonateUserDefinition, getSessionStorage, hideProgress, setSessionStorage, showProgress, switchTenant } from '../RAFComponents/helpers/AppHelper';
import { IDialogProps, isNotEmptyArray, isNotNullAndUndefined, propertyOf } from '../RAFComponents/helpers/utils';
import { ListServiceRequest } from '../RAFComponents/models/Common/ListRequest';
import { TenantRow } from '../RAFComponents/models/Common/TenantRow';
import { LookUpRow } from '../RAFComponents/models/CompositeTypes/LookUpRow';
import * as repositoryActions from "../RAFComponents/store/actions/repositoryActions";
import { LogInAsRequestRow, UserDefinition } from '../RAFModules/ActiveContacts/User/LogInAsRequestRow';
import { getAllUsersByTenantUID } from '../RAFModules/ActiveContacts/User/UserHelper';
import ACLoadingPanel from '../components/shared/ACLoadingPanel';
import { ContentType, RAFButtonConstant, RAFLayout, StorageKey } from '../constants/Common/Constants';
import { IsUserImpersonated, LogoutImpersonatedUser, navigateToDashboard } from './ACutils';
import { UserInfoRow } from '../RAFComponents/models/Common/UserInfoRow';

interface IState {
    isActive: boolean,
    isLoading: boolean;
    isImpersonatedUser: boolean;
    logInAsRequestRow: LogInAsRequestRow;
    allTenants: TenantRow[];
    allTenantUsers: LookUpRow[];
    impersonatedUser: UserDefinition;
}

interface IProps {
    mode?: 'Page' | 'Dialog',
}

function SwitchTenant({ ...props }: PropsWithChildren<IProps & IDialogProps>) {

    const loginUserContext = useContext(LoginUserContext);
    const canImpersonateUser = loginUserContext.canImpersonateUser;

    let rafForm: FormRenderProps | null;
    //const currentUserTenants = msalInstance.currentTenants;
    //let navigate = useNavigate();
    // const loginUserUpdateContext = React.useContext(LoginUserUpdateContext);

    const navigate = useNavigate();

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isActive: false,
            isLoading: false,
            isImpersonatedUser: false,
            logInAsRequestRow: null,
            allTenants: null,
            allTenantUsers: null,
            impersonatedUser: null
        }
    );

    useEffect(() => {
        refresh();
    }, []);

    const refresh = async () => {
        const { isActive } = props;
        if (isActive) {
            setState({ isActive, isLoading: true });
            let logInAsRequestRow = new LogInAsRequestRow();
            logInAsRequestRow.TenantUID = msalInstance.currentTenantId;
            logInAsRequestRow.EmployeeUID = msalInstance.currentUserId;
            const [allTenants, allTenantUsers, isImpersonatedUser] = await Promise.all([
                getAllTenants(),
                getAllUsersByTenantUID(msalInstance.currentTenantId),
                IsUserImpersonated()
            ]);
            let impersonatedUser = getImpersonateUserDefinition();
            setState({ isLoading: false, allTenantUsers, allTenants, logInAsRequestRow, isImpersonatedUser, impersonatedUser });
        } else {
            setState({ isActive });
        }
    };

    const getAllTenants = () => {
        return new Promise<TenantRow[]>((resolve, reject) => {
            let listServiceRequest = new ListServiceRequest();
            listServiceRequest.Skip = 0;
            listServiceRequest.Take = 500;
            repositoryActions
                .postDataAndGetResponse(
                    "Tenant/ListAll",
                    listServiceRequest,
                    null,
                    ContentType.applicationJson
                ).then((response) => {
                    if (isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.data) &&
                        isNotNullAndUndefined(response.data.Entities)) {
                        resolve(response.data.Entities);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error) => error);
        });
    };

    const onSwitchTenantClicked = async (value: LogInAsRequestRow) => {
        if (isNotNullAndUndefined(value)) {
            let progressDiv = showProgress('#switch-Tenant-outerDiv');
            //code start for clear bu on switch tenant
            //sessionStorage.removeItem(StorageKey.CurrentPortal);
            const msalInstanceCurrentUser = msalInstance.currentUser;
            msalInstanceCurrentUser.CurrentPortal = null;
            msalInstanceCurrentUser.CurrentPortalUID = null;
            msalInstanceCurrentUser['currentBusinessUnitId'] = null;
            msalInstance.currentUser = msalInstanceCurrentUser;


            let currentUser: UserInfoRow = JSON.parse(getSessionStorage(StorageKey.currentUser));
            if (isNotNullAndUndefined(currentUser) && isNotNullAndUndefined(currentUser.UserUID)) {
                currentUser.CurrentPortal = null;
                currentUser.CurrentPortalUID = null;
                currentUser['currentBusinessUnitId'] = null;
            }

            setSessionStorage(StorageKey.CurrentPortal, true, null);
            setSessionStorage(StorageKey.currentUser, false, JSON.stringify(currentUser));

            //code end for clear bu on switch tenant
            const response = await switchTenant(value.TenantUID, value.EmployeeUID, false);
            hideProgress(progressDiv);
            if (response) {
                navigateToDashboard();
                if (props.onClose) {
                    props.onClose();
                }
            } else {
                if (props.onClose) {
                    props.onClose();
                }
            }
        } else {
            showWarningToast("Sorry something went wrong !");
        }
    };

    // const onChangeTenant = async (value) => {
    //     let progressDiv = showProgress('#switch-Tenant-outerDiv');
    //     setState({ allTenantUsers: [] });
    //     const selectedTenant = state.allTenants.find(tenant => tenant.DisplayName.toLowerCase() === value.toLowerCase());
    //     if (isNotNullAndUndefined(selectedTenant)) {

    //         const allTenantUsers = await getAllUsersByTenantUID(selectedTenant.UID);
    //         setFormValue(rafForm, propertyOf<LogInAsRequestRow>('EmployeeUID'), null);
    //         hideProgress(progressDiv);
    //         setState({ allTenantUsers });
    //     } else {
    //         hideProgress(progressDiv);
    //         showWarningToast("This tenant does not exist. Please select a valid tenant.");
    //     }
    // };
    const onChangeTenant = async (label, value) => {
        let progressDiv = showProgress('#switch-Tenant-outerDiv');
        setState({ allTenantUsers: [] });
        const allTenantUsers = await getAllUsersByTenantUID(value);
        setFormValue(rafForm, propertyOf<LogInAsRequestRow>('EmployeeUID'), null);
        hideProgress(progressDiv);
        setState({ allTenantUsers });
    };

    const onClickCancelBtn = () => {
        if (props.mode === 'Page') {
            navigate(-1);
        }
        else if (props.onClose) {
            props.onClose();
        }
    };

    const onClickLogOutBtn = () => {
        LogoutImpersonatedUser();
    };

    const bodyContent = () => {
        if (state.isImpersonatedUser) {
            const userName = msalInstance.currentUserName;
            return (
                <div className="w-100 my-4 py-1">
                    <div className="w-100 d-flex justify-content-center text-center">
                        <h3>You are now logged in as {userName}</h3>
                    </div>
                    {/* <div className="w-100 d-flex justify-content-center my-4">
        <div style={{ width: '90px' }}>
            <ButtonComponent type="button" cssClass='e-flat e-primary primary-btn me-2 w-100' onClick={() => this.LogoutImpersonatedUser()}>Logout</ButtonComponent>
        </div>
    </div> */}
                </div>
            );
        } else {
            return (
                <div>
                    <RAFDropdownCC
                        field={propertyOf<LogInAsRequestRow>('TenantUID')}
                        label='Tenant Name'
                        placeholder="Select Tenant"
                        showLabel={true}
                        onChanged={onChangeTenant}
                        allowFiltering
                        required
                    >
                        {isNotEmptyArray(state.allTenants) && state.allTenants.map(item => {
                            return <RAFChoiceOption key={item.UID} label={item.DisplayName} value={item.UID} />;
                        })}
                    </RAFDropdownCC>
                    {/* <RAFTextBox
                    field={propertyOf<LogInAsRequestRow>('TenantUID')}
                    label='Tenant Name'
                    placeholder="Tenant Name"
                    showLabel={true}
                    onInputBlur={onChangeTenant}
                    required
                ></RAFTextBox> */}
                    <RAFDropdownCC
                        field={propertyOf<LogInAsRequestRow>('EmployeeUID')}
                        label='User Name'
                        placeholder="Select User"
                        showLabel={true}
                        allowFiltering
                        required
                    >
                        {isNotEmptyArray(state.allTenantUsers) && state.allTenantUsers.map(item => {
                            return <RAFChoiceOption key={item.UID} label={item.Value} value={item.UID} />;
                        })}
                    </RAFDropdownCC>
                </div>
            );
        }
    };

    const footerContent = () => {
        return (
            <div className='w-100'>
                <div className='row g-2 justify-content-end'>
                    {state.isImpersonatedUser ? (
                        <>
                            <div className='col-auto'>
                                <RAFButtonComponent btnContent='Logout' isPrimary type='button' showIcon={false} className='form-custom-button' onClick={onClickLogOutBtn} />
                            </div>
                            <div className='col-auto'>
                                <RAFButtonComponent action={RAFButtonConstant.Cancel} className='form-custom-button' showIcon={false} onClick={() => onClickCancelBtn()} />
                            </div>
                        </>
                    ) :
                        (
                            <>
                                <div className='col-auto'>
                                    <RAFButtonComponent action={RAFButtonConstant.Ok} isPrimary type='button' showIcon={false} className='form-custom-button'
                                        onClick={() => rafForm && rafForm.form.submit()}
                                    />
                                </div>
                                <div className='col-auto'>
                                    <RAFButtonComponent action={RAFButtonConstant.Cancel} className='form-custom-button' showIcon={false} onClick={() => onClickCancelBtn()} />
                                </div>
                            </>
                        )}
                </div>
            </div>
        );
    };

    const footerTemplate = () => {
        return (
            <div className='px-3 py-2 border-top'>
                {footerContent()}
            </div>
        );
    };

    if (state.isActive) {
        if (state.isLoading === false) {
            if (canImpersonateUser === true || state.isImpersonatedUser === true) {
                return (
                    <RAFForm
                        formRef={(g) => rafForm = g}
                        initialValues={state.logInAsRequestRow}
                        layout={RAFLayout.TwoColumnLayout}
                        onSubmit={onSwitchTenantClicked}
                        className='page_container'
                    >
                        {props.mode === 'Page' ? (
                            <CustomCardWidget
                                footerTemplate={footerTemplate()}
                            >
                                {bodyContent()}
                            </CustomCardWidget>
                        ) : (
                            <>
                                <div className="e-dlg-body-content" id='switch-Tenant-outerDiv'>
                                    {bodyContent()}
                                </div>
                                <div className="e-dlg-footerContent">
                                    {footerContent()}
                                </div>
                            </>
                        )}

                    </RAFForm>
                );
            } else {
                return (
                    <div className="container-fluid px-0">
                        <RAFDeletedRecordState title="Impersonation is not allowed." />
                    </div>
                );
            }
        } else {
            return (
                <div className="container-fluid px-0">
                    <ACLoadingPanel loadingText="Preparing Data..." />
                </div>
            );
        }
    } else {
        return (
            <div></div>
        );
    }
}

export default React.memo(SwitchTenant);
