//import shortid from "shortid";
import { Guid } from "../../RAFComponents/helpers/utils";
import { RAFDataType, RAFUIType } from "../../RAFComponents/models/Common/RAFDataType";

export const SIDEBAR_ITEM = "sidebarItem";
export const ROW = "row";
export const COLUMN = "column";
export const COMPONENT = "component";
export const PANEL = "panel";

// export const SIDEBAR_ITEMS = [{
//     Title: "Basic Components",
//     items: [
//         {
//             //id: shortid.generate(),
//             id: Guid.newGuid(),
//             type: SIDEBAR_ITEM,
//             Title: "Short Text",
//             ComponentType: RAFDataType.Text,
//             IsRequired: false,
//             Hidden: false,
//             IsReadonly: false,
//             DisplayStyle: null,
//             ValueJson: null,
//             component: {
//                 type: RAFDataType.Text,
//             },
//             ShowInView: true,
//             ShowInFilter: true,
//             Searchable: true,
//             InlineEdit: false,
//             IsIdentity: false,
//             IsPrimary: false,
//             UIType: RAFUIType.Singleline,
//             Insertable: false,
//             Updatable: false,
//             IsMasterDetail: false,
//             DefaultValue: null,
//         },
//         {
//             //id: shortid.generate(),
//             id: Guid.newGuid(),
//             type: SIDEBAR_ITEM,
//             Title: "Number",
//             ComponentType: RAFDataType.Number,
//             IsRequired: false,
//             Hidden: false,
//             IsReadonly: false,
//             DisplayStyle: null,
//             ValueJson: null,
//             component: {
//                 type: RAFDataType.Number,
//             },
//             ShowInView: true,
//             ShowInFilter: true,
//             Searchable: true,
//             InlineEdit: false,
//             IsIdentity: false,
//             IsPrimary: false,
//             UIType: null,
//             Insertable: false,
//             Updatable: false,
//             IsMasterDetail: false,
//             DefaultValue: null,
//         },
//         {
//             //id: shortid.generate(),
//             id: Guid.newGuid(),
//             type: SIDEBAR_ITEM,
//             Title: "Check Box",
//             ComponentType: RAFDataType.Boolean,
//             IsRequired: false,
//             Hidden: false,
//             IsReadonly: false,
//             DisplayStyle: null,
//             ValueJson: null,
//             component: {
//                 type: RAFDataType.Boolean,
//             },
//             ShowInView: true,
//             ShowInFilter: true,
//             Searchable: true,
//             InlineEdit: false,
//             IsIdentity: false,
//             IsPrimary: false,
//             UIType: null,
//             Insertable: false,
//             Updatable: false,
//             IsMasterDetail: false,
//             DefaultValue: null,
//         },
//         {
//             //id: shortid.generate(),
//             id: Guid.newGuid(),
//             type: SIDEBAR_ITEM,
//             Title: "Date",
//             ComponentType: RAFDataType.Date,
//             IsRequired: false,
//             Hidden: false,
//             IsReadonly: false,
//             DisplayStyle: null,
//             ValueJson: null,
//             component: {
//                 type: RAFDataType.Date,
//             },
//             ShowInView: true,
//             ShowInFilter: true,
//             Searchable: true,
//             InlineEdit: false,
//             IsIdentity: false,
//             IsPrimary: false,
//             UIType: null,
//             Insertable: false,
//             Updatable: false,
//             IsMasterDetail: false,
//             DefaultValue: null,
//         }, {
//             //id: shortid.generate(),
//             id: Guid.newGuid(),
//             type: SIDEBAR_ITEM,
//             Title: "DateTime",
//             ComponentType: RAFDataType.DateTime,
//             IsRequired: false,
//             Hidden: false,
//             IsReadonly: false,
//             DisplayStyle: null,
//             ValueJson: null,
//             component: {
//                 type: RAFDataType.DateTime,
//             },
//             ShowInView: true,
//             ShowInFilter: true,
//             Searchable: true,
//             InlineEdit: false,
//             IsIdentity: false,
//             IsPrimary: false,
//             UIType: null,
//             Insertable: false,
//             Updatable: false,
//             IsMasterDetail: false,
//             DefaultValue: null,
//         },
//         {
//             //id: shortid.generate(),
//             id: Guid.newGuid(),
//             type: SIDEBAR_ITEM,
//             Title: "Select",
//             ComponentType: RAFDataType.Dropdown,
//             IsRequired: false,
//             Hidden: false,
//             IsReadonly: false,
//             DisplayStyle: "Normal",
//             ValueJson: [{ Id: 1, Name: "yes", DisplayName: "Yes" },
//             { Id: 2, Name: "no", DisplayName: "No" }],
//             component: {
//                 type: RAFDataType.Dropdown,
//             },
//             ShowInView: true,
//             ShowInFilter: true,
//             Searchable: true,
//             InlineEdit: false,
//             IsIdentity: false,
//             IsPrimary: false,
//             UIType: RAFUIType.SimpleDropdown,
//             Insertable: false,
//             Multiselect: false,
//             Updatable: false,
//             IsMasterDetail: false,
//             DefaultValue: null,
//             RelatedEntities: null,
//         },
//         {
//             //id: shortid.generate(),
//             id: Guid.newGuid(),
//             type: SIDEBAR_ITEM,
//             Title: "Long Text",
//             ComponentType: RAFDataType.Multiline,
//             IsRequired: false,
//             Hidden: false,
//             IsReadonly: false,
//             DisplayStyle: null,
//             ValueJson: null,
//             component: {
//                 type: RAFDataType.Multiline,
//             },
//             ShowInView: true,
//             ShowInFilter: true,
//             Searchable: true,
//             InlineEdit: false,
//             IsIdentity: false,
//             IsPrimary: false,
//             UIType: RAFUIType.Multiline,
//             Insertable: false,
//             Updatable: false,
//             IsMasterDetail: false,
//             DefaultValue: null,
//         },
//         {
//             //id: shortid.generate(),
//             id: Guid.newGuid(),
//             type: SIDEBAR_ITEM,
//             Title: "Single Choice",
//             ComponentType: RAFDataType.RadioButton,
//             IsRequired: false,
//             Hidden: false,
//             IsReadonly: false,
//             DisplayStyle: null,
//             ValueJson: [{ Id: 1, Name: "yes", DisplayName: "Yes" },
//             { Id: 2, Name: "no", DisplayName: "No" }],
//             component: {
//                 type: RAFDataType.RadioButton,
//             },
//             ShowInView: true,
//             ShowInFilter: true,
//             Searchable: true,
//             InlineEdit: false,
//             IsIdentity: false,
//             IsPrimary: false,
//             UIType: RAFUIType.RadioButton,
//             Insertable: false,
//             Updatable: false,
//             IsMasterDetail: false,
//             DefaultValue: null,
//         },
//         {
//             //id: shortid.generate(),
//             id: Guid.newGuid(),
//             type: SIDEBAR_ITEM,
//             Title: "Password",
//             ComponentType: RAFDataType.Password,
//             IsRequired: false,
//             Hidden: false,
//             IsReadonly: false,
//             DisplayStyle: null,
//             ValueJson: null,
//             component: {
//                 type: RAFDataType.Password,
//             },
//             ShowInView: true,
//             ShowInFilter: true,
//             Searchable: true,
//             InlineEdit: false,
//             IsIdentity: false,
//             IsPrimary: false,
//             UIType: null,
//             Insertable: false,
//             Updatable: false,
//             IsMasterDetail: false,
//             DefaultValue: null,
//         }, {
//             id: Guid.newGuid(),
//             type: SIDEBAR_ITEM,
//             Title: "Auto Number",
//             ComponentType: RAFDataType.AutoNumber,
//             IsRequired: false,
//             Hidden: false,
//             IsReadonly: false,
//             DisplayStyle: null,
//             ValueJson: null,
//             component: {
//                 type: RAFDataType.AutoNumber,
//             },
//             ShowInView: false,
//             ShowInFilter: true,
//             Searchable: true,
//             InlineEdit: false,
//             IsIdentity: false,
//             IsPrimary: false,
//             UIType: null,
//             Insertable: false,
//             Updatable: false,
//             IsMasterDetail: false,
//             DefaultValue: null,
//         }, {
//             id: Guid.newGuid(),
//             type: SIDEBAR_ITEM,
//             Title: "Formula",
//             ComponentType: RAFDataType.Formula,
//             IsRequired: false,
//             Hidden: false,
//             IsReadonly: false,
//             DisplayStyle: null,
//             ValueJson: null,
//             component: {
//                 type: RAFDataType.Formula,
//             },
//             ShowInView: false,
//             ShowInFilter: true,
//             Searchable: true,
//             InlineEdit: false,
//             IsIdentity: false,
//             IsPrimary: false,
//             UIType: null,
//             Insertable: false,
//             Updatable: false,
//             IsMasterDetail: false,
//             DefaultValue: null,
//         },
//         //{
//         //    //id: shortid.generate(),
//         //    //id: Guid.newGuid(),
//         //    type: SIDEBAR_ITEM,
//         //    Title: "Panel",
//         //    ComponentType: "Panel",
//         //    component: {
//         //        type: "Panel",
//         //    }
//         //}
//     ]
// },
// {
//     Title: "Special Components",
//     items: [
//         {
//             //id: Guid.newGuid(),
//             type: SIDEBAR_ITEM,
//             Title: "Phone",
//             ComponentType: RAFDataType.Form,
//             IsRequired: false,
//             Hidden: false,
//             IsReadonly: false,
//             DisplayStyle: null,
//             ValueJson: null,
//             component: {
//                 type: RAFDataType.Form,
//             },
//             ShowInView: true,
//             ShowInFilter: true,
//             Searchable: true,
//             InlineEdit: false,
//             IsIdentity: false,
//             IsPrimary: false,
//             UIType: null,
//             Insertable: false,
//             Updatable: false,
//             IsMasterDetail: false,
//             DefaultValue: null,
//             Multiselect: true,
//             RelatedEntities: "phone",
//             IsQueryable: true
//         }, {
//             id: Guid.newGuid(),
//             type: SIDEBAR_ITEM,
//             Title: "Address",
//             ComponentType: RAFDataType.Form,
//             IsRequired: false,
//             Hidden: false,
//             IsReadonly: false,
//             DisplayStyle: null,
//             ValueJson: null,
//             component: {
//                 type: RAFDataType.Form,
//             },
//             ShowInView: true,
//             ShowInFilter: true,
//             Searchable: true,
//             InlineEdit: false,
//             IsIdentity: false,
//             IsPrimary: false,
//             UIType: null,
//             Insertable: false,
//             Updatable: false,
//             IsMasterDetail: false,
//             DefaultValue: null,
//             Multiselect: true,
//             RelatedEntities: "address",
//             IsQueryable: true
//         }, {
//             id: Guid.newGuid(),
//             type: SIDEBAR_ITEM,
//             Title: "Email",
//             ComponentType: RAFDataType.Form,
//             IsRequired: false,
//             Hidden: false,
//             IsReadonly: false,
//             DisplayStyle: null,
//             ValueJson: null,
//             component: {
//                 type: RAFDataType.Form,
//             },
//             ShowInView: true,
//             ShowInFilter: true,
//             Searchable: true,
//             InlineEdit: false,
//             IsIdentity: false,
//             IsPrimary: false,
//             UIType: null,
//             Insertable: false,
//             Updatable: false,
//             IsMasterDetail: false,
//             DefaultValue: null,
//             Multiselect: true,
//             RelatedEntities: "email",
//             IsQueryable: true
//         }, {
//             id: Guid.newGuid(),
//             type: SIDEBAR_ITEM,
//             Title: "Form",
//             ComponentType: RAFDataType.Form,
//             IsRequired: false,
//             Hidden: false,
//             IsReadonly: false,
//             DisplayStyle: null,
//             ValueJson: null,
//             component: {
//                 type: RAFDataType.Form,
//             },
//             ShowInView: true,
//             ShowInFilter: true,
//             Searchable: true,
//             InlineEdit: false,
//             IsIdentity: false,
//             IsPrimary: false,
//             UIType: null,
//             Insertable: false,
//             Updatable: false,
//             IsMasterDetail: false,
//             DefaultValue: null,
//             Multiselect: true,
//             RelatedEntities: null,
//             IsQueryable: true
//         },
//     ]
// },
// {
//     Title: "Module Components",
//     items: []
// },
// {
//     Title: "Entity Components",
//     items: []
// },

// ];


function createSidebarItem(title: string, componentType: RAFDataType, uiType: RAFUIType | null, valueJson: any = null, relatedEntities: string | null = null, multiselect: boolean = false): any {
    return {
        id: Guid.newGuid(),
        type: SIDEBAR_ITEM,
        Title: title,
        ComponentType: componentType,
        IsRequired: false,
        Hidden: false,
        IsReadonly: false,
        DisplayStyle: null,
        ValueJson: valueJson,
        component: {
            type: componentType,
        },
        ShowInView: true,
        ShowInFilter: true,
        Searchable: true,
        InlineEdit: false,
        IsIdentity: false,
        IsPrimary: false,
        UIType: uiType,
        Insertable: false,
        Updatable: false,
        IsMasterDetail: false,
        DefaultValue: null,
        Multiselect: multiselect,
        RelatedEntities: relatedEntities,
        IsQueryable: false
    };
}
export const SIDEBAR_ITEMS = [
    {
        Title: "Basic Components",
        items: [
            createSidebarItem("Short Text", RAFDataType.Text, RAFUIType.Singleline),
            createSidebarItem("Number", RAFDataType.Number,null ),
            createSidebarItem("Check Box", RAFDataType.Boolean, null),
            createSidebarItem("Date", RAFDataType.Date, null),
            createSidebarItem("DateTime", RAFDataType.DateTime, null),
            createSidebarItem("Select", RAFDataType.Dropdown, RAFUIType.SimpleDropdown, [{ Id: 1, Name: "yes", DisplayName: "Yes" }, { Id: 2, Name: "no", DisplayName: "No" }]),
            createSidebarItem("Long Text", RAFDataType.Multiline, RAFUIType.Multiline),
            createSidebarItem("Single Choice", RAFDataType.RadioButton, RAFUIType.RadioButton, [{ Id: 1, Name: "yes", DisplayName: "Yes" }, { Id: 2, Name: "no", DisplayName: "No" }]),
            createSidebarItem("Password", RAFDataType.Password, null),
            createSidebarItem("Auto Number", RAFDataType.AutoNumber, null),
            createSidebarItem("Formula", RAFDataType.Formula, null),
        ]
    },
    {
        Title: "Special Components",
        items: [
            createSidebarItem("Phone", RAFDataType.Form, null, null, "phone", true),
            createSidebarItem("Address", RAFDataType.Form, null, null, "address", true),
            createSidebarItem("Email", RAFDataType.Form, null, null, "email", true),
            createSidebarItem("Form", RAFDataType.Form, null, null, null, true),
        ]
    },
    {
        Title: "Module Components",
        items: []
    },
    {
        Title: "Entity Components",
        items: []
    },
];