import { ListRequest } from "../../RAFComponents/models/Common/ListRequest";
import { RAFBPTabName } from "../BPType/RAFBPConstants";



export class RMConstants {
    //static baseAPIUrl = 'http://192.168.1.10:8000/Skytrust2020.Api/';
    //static baseAPIUrl = 'http://localhost:56340/';
    //static rafMasterAPIUrl = 'http://localhost:56340/';
    //static rafMasterAPIUrl = (process.env.REACT_APP_BASE_MASTER_API_URL !== null && process.env.REACT_APP_BASE_MASTER_API_URL !== undefined) ? process.env.REACT_APP_BASE_MASTER_API_URL : 'http://localhost:5001/';
    //static baseAPIUrl = 'http://localhost/RAFMaster.Base.API/';
    //static skytrustUrl = 'http://skytrust.co/';
    static cookieName = 'ACSESSIONC';
    static HorizontalRAFFormElement = 'HorizontalRAFFormElement';
    static VerticalRAFFormElement = 'VerticalRAFFormElement';
    static DropdownFetchCount = 50;
}

export enum RAFEntityType {
    Standard = 'Standard',
    DataList = 'DataList',
    DynamicList = 'DynamicList',
    SystemForm = 'SystemForm',
    UserForm = 'UserForm',
}

export class RMStorageKey {
    static recentClickedMenu = '_recentClickedMenu';
    static currentMenuCategory = 'currentMenuCategory';
    static isAdmin = 'isAdmin';
    //static isUserSettings = 'isUserSettings';
    static entities = 'entities';
    static all_Entities = '_all_Entities';
    static allEntities_entityType = 'allEntities_';
    static allRelatedEntities_modulename = '_allRelatedEntities_';
    static allRelatedAttributes_modulename = '_allRelatedAttributes_';
    static all_Forms = '_all_Forms';
    static bptemplatestepselection = '_bptemplatestepselection_';
    static bpstepselection = '_bpstepselection_';
    static recentBPTemplate = '_recentBPTemplate';
    static recentBPRelatedTo = '_recentBPRelatedTo';
    static currentEntity = 'currentEntity';
    static recentFormLibrary = '_recentFormLibrary';
    static FormLibraryEntity = '_form_library_Entity_';
    static FormLibraryCategoryType = '_form_library_CategoryType_';
    static FormLibararyCustomCategory = '_form_library_CustomCategory_';
}

export enum RAFBusinessCategory {
    Workflow = "Workflow",
    Automation = "Automation",
    BusinessProcess = "Business Process",
    Pipeline = "Pipeline"

}

export enum RAFFormLibraryStepper {
    DefineForm = "Define Form",
    DesignLayout = "Design Form",
    ApplyRules = "Apply Rules",
    Preview = "Preview",
    Publish = "Publish"

}

export enum RAFTriggerMode {
    Created = "Created",
    Modified = "Modified",
    CreatedORModified = "Created/Modified",
    Deleted = "Deleted",
    ViewFilters = "ViewFilters",
    ConditionMatches = "ConditionMatches",
    ChangesFrom = "ChangesFrom",
    Scheduled = "Scheduled",
}

export enum RAFConditionType {
    ValueMatch = "ValueMatch",
    StateChange = "StateChange",
    ValueChange = "ValueChange"
}

export enum RAFPageType {
    Default = "Default",
    Web = "Web",
    Create = "Create",
    Edit = "Edit",
    Details = "Details",
    Lookup = "Lookup",
    RelatedTo = "RelatedTo",
    DetailsSummary = "Details Summary",
    Custom = "Custom",
    Summary = "Summary",
    Card = "Card",
}

export enum RAFSectionType {
    Summary = "Summary",
    Details = "Details",
    RelatedSection = "RelatedSection",
}

export enum RAFSectionTypeValue {
    Summary = "Short Details",
    Details = "Details",
    RelatedSection = "Center List",
}

export enum RAFColumnCountValue {
    Summary = 4,
    Details = 1,
    RelatedSection = 1,
}

export enum RAFEntityDetailsType {
    Attributes = "Attributes",
    PageSettings = "Page Settings",
    CustomFields = "Custom Fields",
    PageLayout = "Page Layout",
    PageRule = "Page Rules",
    Automation = "Automation",
    Template = "Templates",
    ViewManagement = "Views",
    Reports = "Reports",
    FormFields = 'Form Fields',
    EntityFields = 'Standard Fields',
    Pages = "Page Layouts",
    ProcessLibrary = "Business Process",
    Trigger = "Behaviours",
    PageRelatedSection = "Related Sections",
}

export enum RAFBPStepStatus {
    NotCompleted = "Not Completed",
    Completed = "Completed",
    ToDo = "To-Do",
    Approved = "Approved",
    Rejected = "Rejected",
    RequestforInfo = "Request for Info",
    Queued = "Queued",
    Hidden = "Hidden",
    Skipped = "Skipped",
    Initiate = "Initiate",
    RequestApproval = "Request Approval",
    AwaitingforApproval = "Awaiting for Approval",
    Assigned = "Assigned",
    RequestReview = "Request Review",
    AwaitingforReview = "Awaiting for Review",
    Reviewed = "Reviewed",
    Cleared = "Cleared",
    // AwaitingforResponse = "Awaiting for Response",
    // ResponseReceived = "Response Received",
    AwaitingforResponse = "Awaiting",
    ResponseReceived = "Response",
    InitiateRequest = "Request",
    OnHold = "Hold",
    Posted = "Posted",
}



export enum RAFBPStatus {
    Started = "Started",
    Scheduled = "Scheduled",
    InProgress = "In Progress",
    Completed = "Completed",
    Draft = "Draft"
}

export enum RAFBPCurrentStatus {
    Draft = "Draft",
    Published = "Published",
    Archived = "Archived",
}


export enum RAFDocumentViewName {
    Published = "all_content_library",
    Draft = "draft_content_library",
}



export enum RAFDeadlineUnit {
    BusinessDays = "Business days",
    CalendarDays = "Calendar days",
    Weeks = "Weeks",
    Months = "Months",
    Years = "Years"
}

export enum RAFDeadlineCalculation {
    Notset = "Not set",
    AfterProcessStart = "After process start",
    AfterPreviousTaskComplete = "After previous task complete",
    BeforeProcessDeadline = "Before process deadline",
    BeforeStartDate = "Before Start date",
    AfterStartDate = "After Start date",
    AfterAnotherStepComplete = "After another step complete",
    PromptUserWhenRun = "Prompt user",
    OnFileUpload = "On file upload"
}

export enum RAFExecutionOrder {
    //Sequential = "Sequential",
    //Parallel = "Parallel"
    AnyOrder = "Any Order",
    StepByStep = "Step By Step",
    Conditional = "Conditional",
}

export enum RAFWorkspaceRelatedtoType {
    View = "View",
    Module = "Module",
    Form = "Form",
    Category = "Category",
    Weblink = "Web link",
}

export enum RAFTemplateType {
    Email = "Email",
    SMS = "SMS",
    Signature = "Signature",
}

export enum RAFBPStepType {
    Item = "Item",
    Group = "Group",
    Stage = "Stage",
    Task = "To-Do",
    //Task = "Task",
    Checklist = "Checklist",
    //ToDo = "To-Do",
    ToDo = "CheckItem",
    Heading = "Heading",
    Decision = "Decision",
    Approval = "Approval",
    Question = "Question",
    Note = "Page",
    Review = "Review",
    Section = "Page",
    Signature = "Signature",
    FormComponent = "Form Component",
    Instruction = 'Instruction',
    Start = 'Start',
    End = 'End',
    AutoComplete = 'Auto Complete',
    ProcessStep = 'Process Step',
    FormStep = 'Form Step',
}

export enum RAFRelatedSectionStyle {
    AddButton = 'add_button',
    AddwithSummary = 'add_with_summary',
    Relatedsection = 'related_section'
}

export enum RAFProcessAssigneeType {
    ProcessStarter = "Process Starter",
    User = "User",
    Team = "Team",
    Approver = "Approver",
    Reviewer = "Reviewer",
    Partner = "Partner",
    Guest = "Guest",
    None = "None",
};



export class BusinessRuleListRequest extends ListRequest {
    Category?: string;
}




export enum RMReportType {
    Process = 'Process',
    Gallery = 'Gallery',
}

export enum RMReportTypeDisplayName {
    Process = 'Processes',
}



export enum RAFDisplayType {
    Card = 'Card',
    Grid = 'Grid',
    Metric = 'Metric'
}
export enum RAFComponentCategory {
    InputComponents = 'FormInputs',
    SpecialComponents = 'SpecialComponents',
    PageComponents = 'PageComponents',
    Widgets = 'Widgets',
}
export enum RAFComponentCategoryDisplayName {
    InputComponents = 'Input Components',
    SpecialComponents = 'Special Components',
    PageComponents = 'Page Components',
    Widgets = 'Widgets',
}

export enum RAFBPThemeTemplate {
    Induction = 'Induction',
    Inspection = 'Inspection',
    Evaluation = 'Evaluation',
    Default = 'Default',
    Incident = 'Incident',
    Assessment = 'Assessment'
}


export const RAFBPTemplateSettings = [
    { Id: RAFBPThemeTemplate.Induction, TabTitle: RAFBPTabName.Questions, StepType: RAFBPStepType.Question, ShowComplete: true, ShowWizardView: true, DisableSelection: true, ToggleMode: false, CompressImage: true, EnableScore: true },
    { Id: RAFBPThemeTemplate.Inspection, TabTitle: RAFBPTabName.InspectionItems, StepType: RAFBPStepType.Question, ShowComplete: true, ShowWizardView: false, DisableSelection: true, ToggleMode: false, CompressImage: true, EnableScore: true },
    { Id: RAFBPThemeTemplate.Evaluation, TabTitle: RAFBPTabName.Questions, StepType: RAFBPStepType.Question, ShowComplete: true, ShowWizardView: false, DisableSelection: true, ToggleMode: false, CompressImage: true, EnableScore: true },
    { Id: RAFBPThemeTemplate.Incident, TabTitle: RAFBPTabName.Questions, StepType: RAFBPStepType.Question, ShowComplete: true, ShowWizardView: false, DisableSelection: true, ToggleMode: false, CompressImage: false, EnableScore: true },
    { Id: RAFBPThemeTemplate.Assessment, TabTitle: RAFBPTabName.Questions, StepType: RAFBPStepType.Question, ShowComplete: true, ShowWizardView: false, DisableSelection: true, ToggleMode: false, CompressImage: false, EnableScore: false },
    { Id: RAFBPThemeTemplate.Default, TabTitle: RAFBPTabName.ProcessSteps, ShowComplete: true, ShowWizardView: false, DisableSelection: false, ToggleMode: true, CompressImage: true, EnableScore: true },
];

export enum RAFFormLibraryHeaders {
    UserDatalist = 'User DataList',
    Forms = 'Forms',
    Templates = 'Templates',
    Modules = 'System DataList',
}

export enum RAFFormFieldName {
    BusinessProcesss = 'business_process',
    BusinessProcesssUID = 'business_process_uid',
    BusinessProcesssStep = 'business_process_step',
    BusinessProcesssStepUID = 'business_process_step_uid',
}


export enum RAFFormThemeTemplate {
    PageLayout = 'PageLayout',
    CardLayout = 'CardLayout'
}
export enum RAFFormThemeTemplateDisplayname {
    PageLayout = 'Page Layout',
    CardLayout = 'Card Layout'
}

/*doesnt use anywhere*/
export enum RAFDescriptionStyle {
    InfoIcon = 'Info icon',
    Label = 'Label',
}
/*only one file  */
export enum RAFFormElementTabs {
    InputDetails = 'Input Details',
    Rules = 'Rules',
    Layouts = 'Layouts',
    More = 'More',
}

export enum btnIconName {
    save = "far fa-floppy-disk",
    cancel = "far fa-xmark",
    edit = "fas fa-pen-to-square",
    add = "fas fa-plus",
}

export const rafNoViewName = 'No view found';