import { atomFamily } from "recoil";
import { msalInstance } from "../../..";
import { getCollectionNameByModuleName, getRelatedSectionCollectionNameByModuleName } from "../../../RAFComponents/helpers/RAFMenuHelper";
import { isNotEmptyArray, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { RAFTreeNodeModel } from "../../../RAFComponents/models/Common/RAFTreeNodeModel";
import { RAFReportType, RAFReportTypeDisplayName, RAFTenantName, RAFViewType } from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { RMReportType } from "../../../constants/Common/RMConstants";
import { getModuleAvatarIcon } from "../../../helpers/Common/AvatarHelper";

export const atomSelectedLeftMenuTab = atomFamily<any, any>({
    key: "selectedLeftMenuTabState",
    default: null
});

export class RAFHeaderTabItemsRow {
    id?: number;
    text?: string;
    value?: string;
    iconCss?: string;
    disabled?: boolean;
}

export class HeaderTabItems {
    static View = { text: RAFReportTypeDisplayName.View, value: "View" };
    static Analytic = { text: RAFReportTypeDisplayName.Analytic, value: RAFReportType.Analytic };
    static Tags = { text: RAFReportTypeDisplayName.Tags, value: "Tag" };
    static Type = { text: 'Types', value: 'Type' };
    static Category = { text: 'Categories', value: 'Category' };
    static Folders = { text: 'Folders', value: 'Folders' };
    static Files = { text: 'Files', value: 'Files' };
    static All = { text: 'All', value: 'All' };
}
export class BreadCrumbsItems {
    static BreadCrumbsItem(Id: string, DisplayName: string, CollectionName: string) {
        return { Id, DisplayName, CollectionName };
    }

    static Collection = BreadCrumbsItems.BreadCrumbsItem('main', 'Collection', 'Collections');
}

export class getDefaultViewTypes {
    static Dashboard = { text: RAFReportTypeDisplayName.Dashboard, value: RAFReportType.Dashboard };
    static List = { text: RAFReportTypeDisplayName.View, value: RAFReportType.Table };
    static Analytic = { text: RAFReportTypeDisplayName.Analytic, value: RAFReportType.Analytic };
    static Table = { text: RAFReportTypeDisplayName.Table, value: RAFReportType.Table };
    static Record = { text: RAFReportTypeDisplayName.Record, value: RMReportType.Process };
    static Tags = { text: RAFReportTypeDisplayName.Tags, value: RAFReportType.Tags };
    static Gallery = { text: RAFReportTypeDisplayName.Gallery, value: RMReportType.Gallery };
    static Library = { text: RAFReportTypeDisplayName.Library, value: RAFReportType.Table };
}

export const getViewTypeItemCssIcon = (category: string) => {
    let iconCss = '';
    switch (category) {
        //list view
        case RAFViewType.SharedView:
            iconCss = 'fa-solid fa-users-viewfinder';
            break;
        case RAFViewType.MyView:
            iconCss = 'fa-sharp fa-regular fa-rectangle-list';
            break;
        case RAFViewType.SystemView:
            iconCss = 'fa-sharp fa-solid fa-gallery-thumbnails';
            break;

        //Dashboard
        case RAFReportType.Dashboard:
            iconCss = 'fa fa-th-large';
            break;

        //analatic view
        case RAFReportType.Group:
            iconCss = 'fa fa-chart-pie';
            break;
        case RAFReportType.Pivot:
            iconCss = 'fa fa-chart-pie';
            break;
        case RAFReportType.Chart:
            iconCss = 'fa fa-chart-pie';
            break;
        case RAFReportType.Metric:
            iconCss = 'fa fa-chart-pie';
            break;
        case RAFReportType.Table:
            iconCss = 'fa-sharp fa-regular fa-rectangle-list';
            break;
        default:
            iconCss = 'fa-sharp fa-regular fa-rectangle-list';
            break;

    }
    return iconCss;
};

export const getDefaultLeftRelatedSection = (moduleName: string, showRelatedBPLefTab: boolean) => {
    let leftRelatedSection: RAFTreeNodeModel[];
    if (moduleName === RAFEntityName.Account) {
        if (window.innerWidth <= 992) {
            leftRelatedSection = [
                {
                    id: moduleName + "relatedAdditionalInfoDiv",
                    text: "Overview",
                    iconCss: `fa fa-${getModuleAvatarIcon(moduleName)}`
                },
                {

                    id: `${moduleName}relatedTask`,
                    text: getRelatedSectionCollectionNameByModuleName(RAFEntityName.Task),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Task)}`,
                },
                {
                    id: moduleName + "relatedNotes",
                    text: getCollectionNameByModuleName(RAFEntityName.Activity),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Activity)}`,
                },
                {
                    id: moduleName + "relatedContact",
                    text: getCollectionNameByModuleName(RAFEntityName.Contact),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Contact)}`
                },
                {
                    id: moduleName + "relatedTicket",
                    text: getCollectionNameByModuleName(RAFEntityName.Ticket),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Ticket)}`
                },
                {
                    id: moduleName + "relatedDeal",
                    text: getCollectionNameByModuleName(RAFEntityName.Deal),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Deal)}`
                },
                {
                    id: moduleName + "relatedDocument",
                    text: getCollectionNameByModuleName(RAFEntityName.Document),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Document)}`
                },
            ];
        }
        else {
            leftRelatedSection = [
                {
                    id: moduleName + "relatedAdditionalInfoDiv",
                    text: "Overview",
                    iconCss: `fa fa-${getModuleAvatarIcon(moduleName)}`
                },
                {
                    id: moduleName + "relatedContact",
                    text: getCollectionNameByModuleName(RAFEntityName.Contact),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Contact)}`
                },
                {
                    id: moduleName + "relatedTicket",
                    text: getCollectionNameByModuleName(RAFEntityName.Ticket),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Ticket)}`
                },
                {
                    id: moduleName + "relatedDeal",
                    text: getCollectionNameByModuleName(RAFEntityName.Deal),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Deal)}`
                },
                {
                    id: moduleName + "relatedDocument",
                    text: getCollectionNameByModuleName(RAFEntityName.Document),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Document)}`
                },
            ];

        }

        if (msalInstance.currentTenantName !== RAFTenantName.skytrust && showRelatedBPLefTab !== false) {
            leftRelatedSection.push(
                {
                    id: moduleName + "relatedProcess",
                    text: "Processes",
                    iconCss: "fa fa-group-arrows-rotate"
                }
            );
        }
    }
    else if (moduleName === RAFEntityName.Contact) {
        if (window.innerWidth <= 992) {
            leftRelatedSection = [
                {
                    id: moduleName + "relatedAdditionalInfoDiv",
                    text: "Overview",
                    iconCss: `fa fa-${getModuleAvatarIcon(moduleName)}`
                },
                {
                    id: `${moduleName}relatedTask`,
                    text: getRelatedSectionCollectionNameByModuleName(RAFEntityName.Task),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Task)}`,
                },
                {
                    id: moduleName + "relatedNotes",
                    text: getCollectionNameByModuleName(RAFEntityName.Activity),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Activity)}`,
                },
                {
                    id: moduleName + "relatedEmail",
                    text: getCollectionNameByModuleName(RAFEntityName.Email),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Email)}`,
                },
                {
                    id: moduleName + "relatedTicket",
                    text: getCollectionNameByModuleName(RAFEntityName.Ticket),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Ticket)}`
                },
                {
                    id: moduleName + "relatedDeal",
                    text: getCollectionNameByModuleName(RAFEntityName.Deal),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Deal)}`
                },
                {
                    id: moduleName + "relatedDocument",
                    text: getCollectionNameByModuleName(RAFEntityName.Document),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Document)}`
                },
            ];
        }
        else {
            leftRelatedSection = [
                {
                    id: moduleName + "relatedAdditionalInfoDiv",
                    text: "Overview",
                    iconCss: `fa fa-${getModuleAvatarIcon(moduleName)}`
                },
                {
                    id: moduleName + "relatedEmail",
                    text: getCollectionNameByModuleName(RAFEntityName.Email),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Email)}`,
                },
                {
                    id: moduleName + "relatedTicket",
                    text: getCollectionNameByModuleName(RAFEntityName.Ticket),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Ticket)}`
                },
                {
                    id: moduleName + "relatedDeal",
                    text: getCollectionNameByModuleName(RAFEntityName.Deal),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Deal)}`
                },
                {
                    id: moduleName + "relatedDocument",
                    text: getCollectionNameByModuleName(RAFEntityName.Document),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Document)}`
                },
            ];
        }

        if (msalInstance.currentTenantName !== RAFTenantName.skytrust && showRelatedBPLefTab !== false) {
            leftRelatedSection.push(
                {
                    id: moduleName + "relatedProcess",
                    text: "Processes",
                    iconCss: "fa fa-group-arrows-rotate"
                }
            );
        }
    }
    else if (moduleName === RAFEntityName.Deal) {
        if (window.innerWidth <= 992) {
            leftRelatedSection = [
                {
                    id: moduleName + "relatedAdditionalInfoDiv",
                    text: "Overview",
                    iconCss: "fas fa-deal"
                },
                {
                    id: `${moduleName}relatedTask`,
                    text: getRelatedSectionCollectionNameByModuleName(RAFEntityName.Task),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Task)}`,
                },
                {
                    id: moduleName + "relatedNotes",
                    text: getCollectionNameByModuleName(RAFEntityName.Activity),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Activity)}`,
                },
                {
                    id: moduleName + "relatedEmail",
                    text: getCollectionNameByModuleName(RAFEntityName.Email),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Email)}`,
                },
                {
                    id: moduleName + "relatedLinkedContacts",
                    text: getRelatedSectionCollectionNameByModuleName(RAFEntityName.Contact),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Contact)}`,
                },
                {
                    id: moduleName + "relatedDocument",
                    text: getCollectionNameByModuleName(RAFEntityName.Document),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Document)}`
                },
            ];
        }
        else {
            leftRelatedSection = [
                {
                    id: moduleName + "relatedAdditionalInfoDiv",
                    text: "Overview",
                    iconCss: "fas fa-deal"
                },
                {
                    id: moduleName + "relatedEmail",
                    text: getCollectionNameByModuleName(RAFEntityName.Email),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Email)}`,
                },
                {
                    id: moduleName + "relatedLinkedContacts",
                    text: getRelatedSectionCollectionNameByModuleName(RAFEntityName.Contact),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Contact)}`,
                },
                {
                    id: moduleName + "relatedDocument",
                    text: getCollectionNameByModuleName(RAFEntityName.Document),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Document)}`
                },
            ];
        }
        if (msalInstance.currentTenantName !== RAFTenantName.skytrust && showRelatedBPLefTab !== false) {
            leftRelatedSection.push(
                {
                    id: moduleName + "relatedProcess",
                    text: "Processes",
                    iconCss: "fa fa-group-arrows-rotate"
                }
            );
        }
    }
    else if (moduleName === RAFEntityName.Ticket) {
        if (window.innerWidth <= 992) {
            leftRelatedSection = [
                {
                    id: moduleName + "relatedAdditionalInfoDiv",
                    text: "Overview",
                    iconCss: "fa fa-ticket"
                },
                {
                    id: `${moduleName}relatedTask`,
                    text: getRelatedSectionCollectionNameByModuleName(RAFEntityName.Task),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Task)}`,
                },
                {
                    id: moduleName + "relatedNotes",
                    text: getCollectionNameByModuleName(RAFEntityName.Activity),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Activity)}`,
                },
                {
                    id: moduleName + "relatedEmail",
                    text: getCollectionNameByModuleName(RAFEntityName.Email),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Email)}`,
                },
                {
                    id: moduleName + "relatedDocument",
                    text: getCollectionNameByModuleName(RAFEntityName.Document),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Document)}`
                },
            ];
        }
        else {
            leftRelatedSection = [
                {
                    id: moduleName + "relatedAdditionalInfoDiv",
                    text: "Overview",
                    iconCss: "fa fa-ticket"
                },
                {
                    id: moduleName + "relatedEmail",
                    text: getCollectionNameByModuleName(RAFEntityName.Email),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Email)}`,
                },
                {
                    id: moduleName + "relatedDocument",
                    text: getCollectionNameByModuleName(RAFEntityName.Document),
                    iconCss: `fa fa-${getModuleAvatarIcon(RAFEntityName.Document)}`
                },
            ];
        }

        if (msalInstance.currentTenantName !== RAFTenantName.skytrust && showRelatedBPLefTab !== false) {
            leftRelatedSection.push(
                {
                    id: moduleName + "relatedProcess",
                    text: "Processes",
                    iconCss: "fa fa-group-arrows-rotate"
                }
            );
        }
    }
    else if (moduleName === RAFEntityName.ContentLibrary) {

        leftRelatedSection = [
            {
                id: moduleName + "relatedAdditionalInfoDiv",
                text: "Overview",
                iconCss: "fa fa-file-alt"
            },
            {
                id: moduleName + "relatedRecordManagement",
                text: "Record Management",
                iconCss: "fa fa-server"
            },
            {
                id: moduleName + "relatedVersionHistory",
                text: "Version History",
                iconCss: "fa fa-history"
            },
            // {
            //     id: moduleName + "relatedPermissions",
            //     text: "Permissions",
            //     iconCss: "fa fa-user-shield"
            // },
        ];

        if (msalInstance.currentTenantName !== RAFTenantName.skytrust && showRelatedBPLefTab !== false) {
            leftRelatedSection.push(
                {
                    id: moduleName + "relatedProcess",
                    text: "Processes",
                    iconCss: "fa fa-group-arrows-rotate"
                }
            );
        }
    } else if (moduleName === RAFEntityName.Task) {
        leftRelatedSection = [
            {
                id: moduleName + "relatedAdditionalInfoDiv",
                text: "Task Information",
                iconCss: "fa fa-file-alt"
            },
        ];
    } else if (moduleName === RAFEntityName.User) {
        leftRelatedSection = [
            {
                id: moduleName + "relatedAdditionalInfoDiv",
                text: "User Information",
                iconCss: "fa fa-file-alt"
            },
        ];
    }

    return leftRelatedSection;
};

export const getSecondaryLeftMenuTabItems = (moduleName: string, queryAttributes?: QueryAttributeJM[], disableDashboardAnalytic?: boolean) => {

    const defaultHeaderTabItems: RAFHeaderTabItemsRow[] = [
        {
            id: 0,
            text: HeaderTabItems.View.text,
            value: HeaderTabItems.View.value,
            disabled: false,
        },
        {
            id: 1,
            text: HeaderTabItems.Tags.text,
            value: HeaderTabItems.Tags.value,
            disabled: false,
        },
    ];

    const viewAnalyticTagsHeaderTabItems: RAFHeaderTabItemsRow[] = [
        {
            id: 0,
            text: HeaderTabItems.View.text,
            value: HeaderTabItems.View.value,
            disabled: false,
        },
        {
            id: 1,
            text: HeaderTabItems.Tags.text,
            value: HeaderTabItems.Tags.value,
            disabled: false,
        },
        {
            id: 2,
            text: HeaderTabItems.Analytic.text,
            value: HeaderTabItems.Analytic.value,
            disabled: false,
        },
    ];

    const viewAnalyticHeaderTabItems: RAFHeaderTabItemsRow[] = [
        {
            id: 0,
            text: HeaderTabItems.View.text,
            value: HeaderTabItems.View.value,
            disabled: false,
        },
        {
            id: 1,
            text: HeaderTabItems.Analytic.text,
            value: HeaderTabItems.Analytic.value,
            disabled: false,
        }
    ];

    const viewHeaderTabItems: RAFHeaderTabItemsRow[] = [
        {
            id: 0,
            text: HeaderTabItems.View.text,
            value: HeaderTabItems.View.value,
            disabled: false,
        },
    ];

    const templateHeaderTabItems: RAFHeaderTabItemsRow[] = [
        {
            id: 0,
            text: HeaderTabItems.Type.text,
            value: HeaderTabItems.Type.value,
            disabled: false,
        },
    ];

    const categoryHeaderTabItems: RAFHeaderTabItemsRow[] = [
        {
            id: 0,
            text: HeaderTabItems.Category.text,
            value: HeaderTabItems.Category.value,
            disabled: false,
        },
        // {
        //   id: 1,
        //   text: HeaderTabItems.View.text,
        //   value: HeaderTabItems.View.value,
        //   disabled: false,
        // },
        {
            id: 1,
            text: HeaderTabItems.Tags.text,
            value: HeaderTabItems.Tags.value,
            disabled: false,
        },
    ];

    const recordCategoryHeaderTabItems: RAFHeaderTabItemsRow[] = [
        {
            id: 0,
            text: HeaderTabItems.Category.text,
            value: HeaderTabItems.Category.value,
            disabled: false,
        },
        {
            id: 1,
            text: HeaderTabItems.Folders.text,
            value: HeaderTabItems.Folders.value,
            disabled: false,
        },
        {
            id: 2,
            text: HeaderTabItems.Tags.text,
            value: HeaderTabItems.Tags.value,
            disabled: false,
        },
    ];

    const documentHeaderTabItems: RAFHeaderTabItemsRow[] = [
        {
            id: 0,
            text: HeaderTabItems.Folders.text,
            value: HeaderTabItems.Folders.value,
            disabled: false,
        },
        {
            id: 1,
            text: HeaderTabItems.Files.text,
            value: HeaderTabItems.Files.value,
            disabled: false,
        },
        {
            id: 2,
            text: `${HeaderTabItems.All.text} Documents`,
            value: HeaderTabItems.All.value,
            iconCss: 'far fa-files',
            disabled: false,
        },
        {
            id: 3,
            text: HeaderTabItems.Category.text,
            value: HeaderTabItems.Category.value,
            iconCss: "fa fa-folder-open",
            disabled: false,
        },
        // {
        //     id: 3,
        //     text: HeaderTabItems.Type.text,
        //     value: HeaderTabItems.Type.value,
        //     iconCss: "fa fa-folder-o",
        //     disabled: false,
        // },
        {
            id: 4,
            text: HeaderTabItems.Tags.text,
            value: HeaderTabItems.Tags.value,
            iconCss: "fa fa-tag",
            disabled: false,
        },
    ];

    const processTypeHeaderTabItems: RAFHeaderTabItemsRow[] = [
        {
            id: 0,
            text: HeaderTabItems.Type.text,
            value: HeaderTabItems.Type.value,
            disabled: false,
        },
        // {
        //   id: 1,
        //   text: HeaderTabItems.View.text,
        //   value: HeaderTabItems.View.value,
        //   disabled: false,
        // },
        {
            id: 1,
            text: HeaderTabItems.Tags.text,
            value: HeaderTabItems.Tags.value,
            disabled: false,
        },
    ];

    let tabHeaderItems: RAFHeaderTabItemsRow[] = disableDashboardAnalytic === true ? viewHeaderTabItems : viewAnalyticHeaderTabItems;

    if (moduleName === RAFEntityName.ContentLibrary) {
        tabHeaderItems = documentHeaderTabItems;
    } else if (moduleName === RAFEntityName.FormLibrary) {
        tabHeaderItems = categoryHeaderTabItems;

    } else if (
        moduleName === RAFEntityName.BusinessProcess ||
        moduleName === RAFEntityName.BusinessProcessTemplate
    ) {
        tabHeaderItems = processTypeHeaderTabItems;

    } else if (moduleName === RAFEntityName.Template) {
        tabHeaderItems = templateHeaderTabItems;

    } else if (moduleName === RAFEntityName.Email || moduleName === RAFEntityName.Portal) {
        tabHeaderItems = viewHeaderTabItems;
    } else {
        if (isNotEmptyArray(queryAttributes)) {
            let isTagAttributeExist = queryAttributes.find(x => x.AttributeName === 'tags_list_json');
            if (isNotNullAndUndefined(isTagAttributeExist)) {
                if (disableDashboardAnalytic === true) {
                    tabHeaderItems = defaultHeaderTabItems;
                } else {
                    tabHeaderItems = viewAnalyticTagsHeaderTabItems;
                }
            }
        }
    }

    return tabHeaderItems;

};
