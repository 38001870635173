import { RAFCustomFilter } from "../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { BusinessAction } from "../../RAFMaster/RMModules/BusinessRules/BusinessAction";



export enum RAFBPStepStatusDisplayText {
  Completed = "Complete",
  Approved = "Approve",
  Rejected = "Reject",
  Skipped = "Skip",
  Reviewed = "Review",
  RequestforInfo = "Request for Info",
}

export enum RAFBPCurrentStatusIcon {
  Draft = "fas fa-floppy-disk-pen",
  Published = "fas fa-upload",
  Archived = "fas fa-value-absolute",
}

export enum RAFBPViewName {
  Published = "published_process_templates",
  Draft = "draft_process_templates",
  Archived = "archived_process_templates",
  All = "all_process_templates",
}

export enum RAFBPInitiator {
  AllUsers = "All Users",
}

export enum RAFBPStepTypeDisplayName {
  Task = "Task",
  ToDo = "Checklist",
  Group = "Stage",
  // Group = "Multi-Step Group",
  Decision = "Decision",
  UserInput = "User Input",
  Form = "Form",
}

export enum RAFBPStepTypeIcon {
  Group = "fas fa-list-tree",
  Task = "far fa-calendar-circle-user",
  ToDo = "far fa-clipboard-check",
  Approval = "far fa-person-circle-check",
  Question = "far fa-ballot-check",
  Note = "far fa-memo",
  Review = "fas fa-star-sharp-half-stroke",
  Heading = "fas fa-heading",
  Signature = "fas fa-signature",
  FormComponent = "fas fa-signature",
  Decision = "fas fa-random",
  Instruction = "fad fa-list-timeline",
  FormStep = "fad fa-list-timeline",
  ProcessStep = "fad fa-list-timeline",
}

export enum RAFBPStepTemplateStatus {
  Active = "Active",
  InActive = "InActive",
  Hidden = "Hidden",
}

export enum RAFBPAction {
  Continue = "continue",
  End = "end",
}

export class RAFBpStepTemplateCloneRequest {
  SourceUID?: string;
  DestinationUID?: string;
  DestinationStepTemplateUID?: string;
}


/*constant copy from RMConstant constants used in BusinessProcess*/

export enum RAFProcessFormPropertyName {
  BusinessProcesss = "BpStatusBusinessProcess",
  BusinessProcesssUID = "BpStatusBusinessProcessUID",
  ProcessStatus = "BpStatusProcessStatus",
  StepCount = "BpStatusStepCount",
  CompletedCount = "BpStatusCompletedCount",
  DueDate = "BpStatusDueDate",
  OverDueStepCount = "BpStatusOverdueStepCount",
  Description = "BpStatusDescription",
}

export enum RAFBPTab {
  ProcessSteps = "ProcessSteps",
  RunningProcess = "RunningProcess",
  CompletedProcess = "CompletedProcess",
  VersionHistory = "VersionHistory",
  Transactions = "Transactions",
  Questions = "Questions",
  InspectionItems = "InspectionItems",
  Designer = "Designer",
  Inprogress = "Inprogress",
  Scheduled = "Scheduled",
  History = "History",
  Versions = "Versions",
  Details = "Details",
  Steps = "Steps",
  Records = "Records",
  Runs = "RunsByStatus",
}

export enum RAFBPTabName {
  ProcessSteps = "Process Steps",
  RunningProcess = "Running Process",
  CompletedProcess = "Completed Process",
  VersionHistory = "Version History",
  Transactions = "Transactions",
  Questions = "Questions",
  InspectionItems = "Inspection Items",
  Designer = "Designer",
  Inprogress = "Inprogress",
  Scheduled = "Scheduled",
  History = "History",
  Versions = "Versions",
  Details = "Details",
  Steps = "Steps",
  Records = "Records",
  Runs = "Runs By Status",
  FormDesigner = "Form Designer",
  WorkflowSteps = "Workflow Steps"
}



export enum RAFProcessViewName {
  Completed = "completed_process",
  Running = "running_business_process",
  Scheduled = "scheduled_business_process",
  All = "all_processes"
}

export class BPStepDecisionModel {
  Decision: BPStepDecision[];
}
export class BPStepDecision {
  Conditions?: RAFCustomFilter;
  Actions?: BusinessAction;
}