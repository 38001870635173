import { PropsWithChildren } from 'react';
import RAFTextArea from '../../../RAFComponents/Inputs/RAFTextArea';
import { RAFDefaultFieldProps, RAFTextAreaProps } from '../../../RAFComponents/Inputs/RFFUtils';
import { RAFAttributesContext } from '../../../RAFComponents/Providers/RAFAttributeRelatedListProvider';
import { GetFieldsDisplayName } from '../../../RAFComponents/helpers/AppHelper';

//const RAFTextBox = ({ field, label, required }) => {

function ACTextArea<T>(
    { field, label, width, onChanged, children,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
        disabled = RAFDefaultFieldProps.disabled,
        showClearButton = RAFDefaultFieldProps.showClearButton,
        validate = RAFDefaultFieldProps.validate,
        ...props }: PropsWithChildren<RAFTextAreaProps<T>>,
) {
    return (
        <div>
            <div className="row align-items-center gx-1 gx-md-2 gx-xl-3" id={"rafdiv" + field.toString()} >
                <div className="col-12 d-flex">
                    {showLabel && showLabel === true &&
                        <RAFAttributesContext.Consumer>
                            {({ queryAttributes, attributeRelatedList }) => {
                                return (
                                    <label
                                        className={required ? "form-label required" : "form-label"}
                                    >
                                        {
                                            GetFieldsDisplayName(
                                                queryAttributes,
                                                field.toString(),
                                                label, attributeRelatedList
                                            )}{" "}
                                    </label>
                                );
                            }}
                        </RAFAttributesContext.Consumer>
                    }
                </div>
                <div className="col-12 d-flex">
                    <div className="w-100">
                        <RAFTextArea<T> field={field} label={label} required={required} showLabel={false} width={width && width !== null ? width : '100%'} validate={validate} onChanged={onChanged} {...props} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ACTextArea;