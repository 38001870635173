import React, { PropsWithChildren } from 'react';
import { msalInstance } from '..';
import RAFChoiceOption from '../RAFComponents/Inputs/RAFChoiceOption';
import RAFDropdownCC from '../RAFComponents/Inputs/RAFDropdownCC';
import RAFForm from '../RAFComponents/Inputs/RAFForm';
import RAFButtonComponent from '../RAFComponents/Navigation/RAFButtonComponent';
import { hideProgress, showProgress, switchOrganisation } from '../RAFComponents/helpers/AppHelper';
import { IDialogProps, isNotEmptyArray } from '../RAFComponents/helpers/utils';
import { RAFButtonConstant, RAFLayout } from '../constants/Common/Constants';
import { navigateToDashboard } from './ACutils';

function SwitchOrganisation({ ...props }: PropsWithChildren<IDialogProps>) {
    const currentUserTenants = msalInstance.currentTenants;
    //let navigate = useNavigate();
    // const loginUserUpdateContext = React.useContext(LoginUserUpdateContext);

    // useEffect(() => {

    // }, []);

    const onSwitchOrganisationClicked = (values) => {
        const selectedValue = values['Tenant'];
        let progressDiv = showProgress('#switch-Organisation-outerDiv');
        switchOrganisation(
            selectedValue
        )
            .then((response) => {
                hideProgress(progressDiv);
                if (response) {
                    props.onClose();
                    navigateToDashboard();

                    // if (isNotNullAndUndefined(loginUserUpdateContext)) {
                    //     loginUserUpdateContext.updateUserInfo(currentUser);
                    //     navigateToDashboard(navigate);
                    // } else {
                    //     navigateToDashboard();
                    // }

                    //navigateToDashboard();
                } else {
                    props.onClose();
                }
            })
            .catch((error) => error);
    };


    return (
        <RAFForm layout={RAFLayout.TwoColumnLayout} onSubmit={onSwitchOrganisationClicked}>
            <div className="e-dlg-body-content" id='switch-Organisation-outerDiv'>
                <div className="row align-items-center m-1">
                    <div className="col-12">
                        <label className="form-label required">Organisation Name </label>
                    </div>
                    <div className="col-12">
                        <div className="row" id="rafdiv_switch_Tenant">
                            <div className="col-md-12 form-group align-items-center py-0">
                                <div className="w-100">
                                    <RAFDropdownCC field="Tenant" placeholder="Select Organisation" showLabel={false} required showClearButton={false} initialValue={msalInstance.currentTenantId}>
                                        {isNotEmptyArray(currentUserTenants) && currentUserTenants.map(item => {
                                            return <RAFChoiceOption key={item.UID} label={item.DisplayName} value={item.UID} />;
                                        })}
                                    </RAFDropdownCC>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="e-dlg-footerContent">
                <RAFButtonComponent type="submit" isPrimary action={RAFButtonConstant.Ok} showIcon={false} className='form-custom-button' />
                <RAFButtonComponent action={RAFButtonConstant.Cancel} showIcon={false} className='form-custom-button' onClick={() => props.onClose()} />
            </div>
        </RAFForm>
    );
}

export default React.memo(SwitchOrganisation);
