import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { PropsWithChildren, useContext } from 'react';
import { FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFFieldLabel from "../../../RAFComponents/Inputs/RAFFieldLabel";
import RAFInputGroup from "../../../RAFComponents/Inputs/RAFInputGroup";
import RAFTextBox from "../../../RAFComponents/Inputs/RAFTextBox";
import { RAFDefaultFieldProps, RAFFieldProps, RAFFormContext } from "../../../RAFComponents/Inputs/RFFUtils";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { PhoneType } from "./PhoneRow";

interface IProps {
    showAdd?: boolean;
    dropDownItems?: { label: string, value: string; }[];
    checkUnique?: boolean;
}

function RAFPhone1<T>(
    { field, label, dropDownItems,
        labelClassName, description,
        showAdd = true,
        checkUnique = true,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
    }: PropsWithChildren<RAFFieldProps<T> & IProps>,
) {

    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

    const selectedPhoneListValues = checkUnique === true && isNotNullAndUndefined(rafFormContextValue) &&
        isNotNullAndUndefined(rafFormContextValue.values) && isNotNullAndUndefined(rafFormContextValue.values[field.toString()]) ? rafFormContextValue.values[field.toString()] : [];

    const disablePhoneDropDownItemsValue = [];
    selectedPhoneListValues && selectedPhoneListValues.forEach(item => {
        if (isNotNullAndUndefined(item.PhoneType))
            disablePhoneDropDownItemsValue.push({ label: item.PhoneType, value: item.PhoneType });
    });

    const phoneDropDownItems = isNotNullAndUndefined(dropDownItems) && dropDownItems.length > 0 ? dropDownItems : [{ label: "Home", value: PhoneType.Home },
    { label: "Work", value: PhoneType.Work },
    { label: "Personal", value: PhoneType.Personal },
    { label: "Toll-Free", value: PhoneType.TollFree },
    { label: "Fax", value: PhoneType.Fax }];

    const onAddClick = (field: keyof T | string) => {
        if (isNotNullAndUndefined(rafFormContextValue) && isNotNullAndUndefined(rafFormContextValue.form) && isNotNullAndUndefined(rafFormContextValue.form.mutators)) {
            rafFormContextValue.form.mutators.push(field.toString(), { PhoneType: null, PhoneNumber: null });
        }
    };

    const onDeleteClick = (fields, index, required1) => {
        if (isNotNullAndUndefined(required1) && required1 === true) {
            if (isNotNullAndUndefined(fields) && fields.length > 1) {
                fields.remove(index);
            }
            else {
                showWarningToast('Atleast one phone number is required');
            }
        }
        else {
            fields.remove(index);
        }
    };

    const onChangePhoneType = (value, label, index, fieldName) => {
        if (isNotNullAndUndefined(rafFormContextValue) && isNotNullAndUndefined(rafFormContextValue.form) && isNotNullAndUndefined(rafFormContextValue.form.mutators)) {
            rafFormContextValue.form.mutators.setValue(fieldName, null);
        }
    };

    return (
        <div className="app-container p-0" id={"rafdiv" + field.toString()} >
            <div className="d-flex justify-content-between">
                {showLabel && showLabel === true &&
                    <RAFFieldLabel
                        field={field}
                        label={label}
                        required={required}
                        labelClassName={labelClassName}
                        description={description}
                    ></RAFFieldLabel>
                }
            </div>
            <div className="list mb-3">
                <div className="row g-2">
                    <FieldArray name={field.toString()}>
                        {({ fields }) =>
                            fields.map((name, index) => (
                                <div className="col-12" key={name}>
                                    <div className="row g-2 align-items-center phoneSection">
                                        <div className="col">
                                            <RAFDropdownCC field={`${name}.PhoneType`} showLabel={false} placeholder="Select Type"
                                                {...checkUnique === true ? { disableItems: disablePhoneDropDownItemsValue } : {}}
                                                onChanged={(label, value) => { if (checkUnique === true) onChangePhoneType(label, value, index, `${name}.PhoneNumber`); }}
                                                showClearButton={false}
                                            >
                                                {phoneDropDownItems.map((item, index) => {
                                                    return <RAFChoiceOption key={index} label={item.label} value={item.value} />;
                                                })}
                                                {/* <RAFChoiceOption label="Home" value={PhoneType.Home} />
                                            <RAFChoiceOption label="Work" value={PhoneType.Work} />
                                            <RAFChoiceOption label="Personal" value={PhoneType.Personal} />
                                            <RAFChoiceOption label="Toll-Free" value={PhoneType.TollFree} />
                                            <RAFChoiceOption label="Fax" value={PhoneType.Fax} />*/}
                                            </RAFDropdownCC>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 inputFieldContent">
                                            <RAFInputGroup showLabel={false} label="">
                                                <span className="e-input-group-icon fa fa-phone-alt"></span>
                                                <div className="e-input-in-wrap">
                                                    <div className="w-100">
                                                        <RAFTextBox field={`${name}.PhoneNumber`} required={required} showLabel={false} placeholder="+61 4 xxxx xxxx" />
                                                    </div>
                                                </div>
                                            </RAFInputGroup>
                                        </div>
                                        <div className="col-auto inputFieldDeleteButton">
                                            <div>
                                                <ButtonComponent type="button" onClick={() => onDeleteClick(fields, index, required)} title="Click to remove" className="removeItem">
                                                    <span className="fas fa-trash"></span>
                                                </ButtonComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </FieldArray>
                    {showAdd && ((checkUnique === true && phoneDropDownItems.length !== disablePhoneDropDownItemsValue.length) || checkUnique === false) &&
                        <div className="col-12">
                            <button type="button" className="link-button" onClick={() => onAddClick(field)}> Add Phone </button>
                        </div>
                    }
                </div>
            </div >
        </div >
    );
}

export default RAFPhone1;

