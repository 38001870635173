
import { IsNotNullOrWhiteSpace, IsNullOrWhiteSpace } from "../../RAFComponents/helpers/utils";
import { TimeSheetSummaryModuleName } from "../../RAFModules/ActiveContacts/ServiceTransaction/ServiceTimesheetSummary/ServiceTimesheetSummaryHelper";
import { CareEsioEntity } from "../../constants/CareESIO/CareEsioConstants";
import { RAFEntityName } from "../../constants/Common/EntityConstants";

export function getURLPrefixByModuleName(moduleName: string, defaultValue?: string, entityUID?: string, entityName?: string) {
    if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === RAFEntityName.Employee.toLowerCase()) {
        return "Employee";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === TimeSheetSummaryModuleName.InvoiceTransaction.toLowerCase()) {
        return "InvoiceTransaction";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === TimeSheetSummaryModuleName.PayrollTransaction.toLowerCase()) {
        return "PayrollTransaction";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === CareEsioEntity.CarePayItem.EntityName.toLowerCase()) {
        return "PayItem";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === CareEsioEntity.CarePriceListItem.EntityName.toLowerCase()) {
        return "PriceListItem";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === RAFEntityName.StandardizedLibrary.toLowerCase()) {
        return "StandardizedLibrary";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === RAFEntityName.ContentLibrary.toLowerCase()) {
        return "ContentLibrary";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === CareEsioEntity.ChecklistTemplate.EntityName.toLowerCase()) {
        return "ChecklistTemplate";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === CareEsioEntity.ChecklistTemplateItem.EntityName.toLowerCase()) {
        return "ChecklistTemplateItem";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === CareEsioEntity.ChecklistItemTrans.EntityName.toLowerCase()) {
        return "ChecklistItemTrans";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === CareEsioEntity.TimeChangeRequest.EntityName.toLowerCase()) {
        return "TimeChangeRequest";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === CareEsioEntity.InvoiceItem.EntityName.toLowerCase()) {
        return "InvoiceItem";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === CareEsioEntity.Invoice.EntityName.toLowerCase()) {
        return "Invoice";
    }
    else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === CareEsioEntity.CareServiceContract.EntityName.toLowerCase()) {
        return "ServiceContract";
    }
    else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === RAFEntityName.AcknowledgementTracking.toLowerCase()) {
        return "AcknowledgementTracking";
    }
    else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === CareEsioEntity.CareServiceContractItem.EntityName.toLowerCase()) {
        return "ServiceContractItem";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === CareEsioEntity.RecurringConfig.EntityName.toLowerCase()) {
        return "RecurringConfig";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === RAFEntityName.FormLibrary.toLowerCase()) {
        return "FormLibrary";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === RAFEntityName.FormSchedule.toLowerCase()) {
        return "FormSchedule";
    } else if (IsNotNullOrWhiteSpace(moduleName) && moduleName.toLowerCase() === RAFEntityName.AvailabilitySchedule.toLowerCase()) {
        return "AvailabilitySchedule";
    } else if (!IsNullOrWhiteSpace(defaultValue)) {
        return defaultValue;
    } else if (!IsNullOrWhiteSpace(entityUID) || !IsNullOrWhiteSpace(entityName)) {
        return 'DataList';
    } else {
        const entityNameMap = {
            [RAFEntityName.Account.toLowerCase()]: "Account",
            [RAFEntityName.Contact.toLowerCase()]: "Contact",
            [RAFEntityName.Deal.toLowerCase()]: "Deal",
            [RAFEntityName.Ticket.toLowerCase()]: "Ticket",
            [RAFEntityName.Complaint.toLowerCase()]: "Complaint",
            [RAFEntityName.Task.toLowerCase()]: "Task",
            [RAFEntityName.Activity.toLowerCase()]: "Activity",
            [RAFEntityName.ServiceTransaction.toLowerCase()]: "ServiceTransaction",
            [RAFEntityName.ShiftOffer.toLowerCase()]: "ShiftOffer",
            [RAFEntityName.BusinessProcess.toLowerCase()]: "BusinessProcess",
            [RAFEntityName.BusinessProcessStep.toLowerCase()]: "BusinessProcessStep",
            [RAFEntityName.BusinessProcessTemplate.toLowerCase()]: "BusinessProcessTemplate",
            [RAFEntityName.BusinessProcessType.toLowerCase()]: "Category",
            [RAFEntityName.BusinessProcessStepTemplate.toLowerCase()]: "BusinessProcessStepTemplate",
            [RAFEntityName.BusinessProcessStage.toLowerCase()]: "BusinessProcessStage",
            [RAFEntityName.BusinessRule.toLowerCase()]: "BusinessRule",
            [RAFEntityName.User.toLowerCase()]: "User",
            [RAFEntityName.Role.toLowerCase()]: "PermissionGroup",
            [RAFEntityName.ContentLibrary.toLowerCase()]: "ContentLibrary",
            [RAFEntityName.VersionHistory.toLowerCase()]: "VersionHistory",
            [RAFEntityName.Email.toLowerCase()]: "Email",
            [RAFEntityName.Team.toLowerCase()]: "Team",
            [RAFEntityName.UserSignature.toLowerCase()]: "UserSignature",
            [RAFEntityName.Contract.toLowerCase()]: "Contract",
            [RAFEntityName.FormLibrary.toLowerCase()]: "FormLibrary",
            [RAFEntityName.Entity.toLowerCase()]: "Entity",
            [RAFEntityName.Template.toLowerCase()]: "Template",
            [RAFEntityName.Portal.toLowerCase()]: "Portal",
            [RAFEntityName.PortalItem.toLowerCase()]: "PortalItem",
            [RAFEntityName.RecordCategory.toLowerCase()]: "RecordCategory",
            [RAFEntityName.Document.toLowerCase()]: "Document",
            [RAFEntityName.FormData.toLowerCase()]: "FormData",
            [RAFEntityName.Report.toLowerCase()]: "Report",
            [RAFEntityName.Dashboard.toLowerCase()]: "Dashboard",
            [RAFEntityName.View.toLowerCase()]: "View",
            [RAFEntityName.BusinessRuleTrigger.toLowerCase()]: "BusinessRuleTrigger",
            [RAFEntityName.PageLayout.toLowerCase()]: "PageLayout",
            [RAFEntityName.UserFocus.toLowerCase()]: "UserFocus",
            [RAFEntityName.DealStage.toLowerCase()]: "DealStage",
            [RAFEntityName.Department.toLowerCase()]: "Department",
            [RAFEntityName.Location.toLowerCase()]: "Location",
            [RAFEntityName.Product.toLowerCase()]: "Product",
            [RAFEntityName.Suggestion.toLowerCase()]: "Suggestion",
            [RAFEntityName.Import.toLowerCase()]: "Import",
            [RAFEntityName.TaskTitle.toLowerCase()]: "TaskTitle",
            [RAFEntityName.Employee.toLowerCase()]: "Employee",
            [TimeSheetSummaryModuleName.InvoiceTransaction.toLowerCase()]: "InvoiceTransaction",
            [TimeSheetSummaryModuleName.PayrollTransaction.toLowerCase()]: "PayrollTransaction",
        };

        let outVal = 'DataList';
        if (!IsNullOrWhiteSpace(moduleName)) {
            const lowerCaseModuleName = moduleName.toLowerCase();
            if (entityNameMap.hasOwnProperty(lowerCaseModuleName)) {
                outVal = entityNameMap[lowerCaseModuleName];
            }
        }
        return outVal;
    }
};