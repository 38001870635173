import { TextBox } from "@syncfusion/ej2-react-inputs";
import { Sidebar, TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import React, { PropsWithChildren, useContext, useEffect } from "react";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilCallback } from "recoil";
import RAFViewMoreMenuOptions from "../../../../RAFComponents/RAFViewPanels/RAFViewMoreMenuOptions";
import {
  SetGridState,
  getUserViewsByModuleName,
  isDisableDashboardAnalytic,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  RAFEventName,
  subscribeRAFEvent,
  unsubscribeRAFEvent,
} from "../../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  ConvertToElementID,
  IsNotNullOrWhiteSpace,
  deepEqual,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import {
  atomSelectedGridView,
  atomSelectedViewState,
  getDefaultActiveCurrentViewFromList,
  getViewsReportsDashboards,
} from "../../../../RAFModules/Common/List/IndexHelper";
import {
  CurrentWorkspaceContext,
  WorkspaceLeftItemsContext,
} from "../../../../RAFModules/Common/Providers/WorkspaceContextProvider";
import {
  PortalTreeViewItemRow,
  convertViewRowToWorkspaceViewItem,
} from "../../../../RAFModules/Common/Workspace/WorkspaceItem/Helper/WorkspaceItemHelper";
import {
  BrowserIsDevice,
  RAFReportType,
  RAFTreeViewMenu,
} from "../../../../constants/Common/Constants";
import { RAFWorkspaceRelatedtoType } from "../../../../constants/Common/RMConstants";
import { navigateToDashboard } from "../../../../helpers/ACutils";
import RAFProfileCard from "../../../../helpers/RAFProfileCard/RAFProfileCard";
//import { UserFeedsContext } from "../../UserFeedsContextProvider";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { MenuCategory } from "../../../../constants/MenuConstant/RAFMenuCategory";
import { getModuleAvatarIcon } from "../../../../helpers/Common/AvatarHelper";
import sidebarToggleImg from "./../../../../assets/sidebar_toggle_img.png";
import "./../../../../styles/NavigationMenu.scss";
import "./NavigationMenuTreeView.scss";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { LoginUserContext } from "../../../../RAFAuthentication/RAFLoginUserContextProvider";

const productLogoReversal = `${window.location.origin}/Content/img/workesio logo reversal.png`;
const productLogo = `${window.location.origin}/Content/img/workesio logo.png`;
const productShortLogoReversal = `${window.location.origin}/Content/img/workesio logo reversal short.png`;
const productShortLogo = `${window.location.origin}/Content/img/workesio logo reversal short.png`;

interface IProps {
  theme?: "White" | "Dark";
  mode?: "SideBar" | "FullScreen";
}
function NavigationMenuTreeView({ ...props }: PropsWithChildren<IProps>) {
  let treeviewObj: TreeViewComponent;

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const workspaceLeftItemsContext = useContext(WorkspaceLeftItemsContext);
  const portalTreeViewItems = isNotNullAndUndefined(workspaceLeftItemsContext)
    ? workspaceLeftItemsContext.portalTreeViewItems
    : [];

  const currentMenuCategory =
    isNotNullAndUndefined(workspaceLeftItemsContext) &&
    isNotNullAndUndefined(workspaceLeftItemsContext.currentMenuCategory)
      ? workspaceLeftItemsContext.currentMenuCategory
      : MenuCategory.Default;

  const leftMenuItemOuterDivKey = isNotEmptyArray(portalTreeViewItems)
    ? `${currentMenuCategory}_${portalTreeViewItems.length}`
    : `${currentMenuCategory}_0`;

  // let currentMenuCategory = getSessionStorage(
  //     RMStorageKey.currentMenuCategory,
  //     false
  // );

  const currentWorkspaceContext = React.useContext(CurrentWorkspaceContext);
  const currentWorkspace = isNotNullAndUndefined(currentWorkspaceContext)
    ? currentWorkspaceContext.currentWorkspace
    : null;
  const loginUserContext = useContext(LoginUserContext);

  let navigate = useNavigate();

  useEffect(() => {
    // refresh();
    // Initialization of Sidebar.
    // function refresh() {
  }, [portalTreeViewItems]);

  useEffect(() => {
    subscribeRAFEvent(`${RAFEventName.ToggleSideMenu}`, toggleclick);

    return () => {
      unsubscribeRAFEvent(`${RAFEventName.ToggleSideMenu}`, toggleclick);
    };
  });

  let sidebarMenu: Sidebar;

  // Initialization of Sidebar.

  // function refresh() {

  let width = BrowserIsDevice ? "90%" : "328px";

  sidebarMenu = new Sidebar({
    width: width,
    target: "#mainTabDiv",
    // mediaQuery: '(min-width: 992px)',
    // isOpen: BrowserIsDevice ? false : true,
    isOpen: false,
    enableGestures: false,
    enableDock: true,
    dockSize: "80px",
    //'mouseOver': onmouseover,
    // showBackdrop: BrowserIsDevice ? true : false,
    created: function () {
      // Add mouseover event
      this.element.addEventListener("mouseover", function () {
        if (sidebarMenu && !sidebarMenu.isOpen && sidebarMenu.type === "Over") {
          sidebarMenu.show();
        }
      });

      // Add mouseout event
      this.element.addEventListener("mouseout", function () {
        if (sidebarMenu && sidebarMenu.isOpen && sidebarMenu.type === "Over") {
          sidebarMenu.hide();
        }
      });
    },
  });
  if (BrowserIsDevice) {
    sidebarMenu.enableDock = false;
  }
  // if (!BrowserIsDevice) {
  sidebarMenu.appendTo("#sideTree");
  // }
  let sidebarBackDrop = document.querySelector(".e-sidebar__backdrop");
  if (isNotNullAndUndefined(sidebarBackDrop)) {
    sidebarBackDrop.classList.remove("show");
  }

  //Defines the rendering code blocks for the Textbox component.
  let inputObj: TextBox = new TextBox({
    placeholder: "Search...",
  });
  inputObj.appendTo("#resSearch");

  function toggleclick(backDropClicked?: boolean) {
    let sidebarToggleBtn = document.getElementById("e-sidebar__toggleBtn");
    let sidebarBackDrop = document.querySelector(".e-sidebar__backdrop");
    let sidebar__toggleBtn__collapse = document.querySelector(
      "#btn_e-sidebar__toggleBtn__collapse"
    );
    let sidebar__toggleBtn__expand = document.querySelector(
      "#btn_e-sidebar__toggleBtn__expand"
    );
    console.log();
    if (
      isNotNullAndUndefined(sidebar__toggleBtn__collapse) &&
      isNotNullAndUndefined(sidebar__toggleBtn__expand)
    ) {
      if (sidebarMenu.isOpen === true && sidebarMenu.type === "Over") {
        sidebar__toggleBtn__collapse.classList.remove("hidden");
        sidebar__toggleBtn__expand.classList.add("hidden");
      } else {
        sidebar__toggleBtn__collapse.classList.add("hidden");
        sidebar__toggleBtn__expand.classList.remove("hidden");
      }
    }
    if (sidebarMenu.isOpen === true) {
      isNotNullAndUndefined(sidebarToggleBtn) &&
        sidebarToggleBtn.classList.remove("hidden");
    } else {
      isNotNullAndUndefined(sidebarToggleBtn) &&
        sidebarToggleBtn.classList.add("hidden");
    }
    if (BrowserIsDevice === true && isNotNullAndUndefined(sidebarBackDrop)) {
      if (backDropClicked === true) {
        sidebarBackDrop.classList.remove("show");
        // sidebarMenu.isOpen = false;
        if (
          isNotNullAndUndefined(sidebarMenu) &&
          isNotNullAndUndefined(sidebarMenu.close)
        ) {
          sidebarMenu.close();
        } else {
          sidebarMenu.isOpen = false;
        }
      } else if (sidebarMenu.isOpen === true) {
        sidebarMenu.type = "Over";
        sidebarBackDrop.classList.remove("show");
        // sidebarMenu.isOpen = false;
        if (
          isNotNullAndUndefined(sidebarMenu) &&
          isNotNullAndUndefined(sidebarMenu.close)
        ) {
          sidebarMenu.close();
        } else {
          sidebarMenu.isOpen = false;
        }
      } else {
        sidebarMenu.type = "Auto";
        sidebarBackDrop.classList.add("show");
        // sidebarMenu.isOpen = true;
        if (
          isNotNullAndUndefined(sidebarMenu) &&
          isNotNullAndUndefined(sidebarMenu.open)
        ) {
          sidebarMenu.open();
        } else {
          sidebarMenu.isOpen = true;
        }
      }
    } else {
      if (sidebarMenu.isOpen === true) {
        console.log("Over");
        sidebarMenu.type = "Over";
      } else {
        console.log("Auto");
        sidebarMenu.type = "Auto";
      }
      // sidebarMenu.isOpen = !sidebarMenu.isOpen;
      if (
        isNotNullAndUndefined(sidebarMenu) &&
        isNotNullAndUndefined(sidebarMenu.toggle)
      ) {
        sidebarMenu.toggle();
      } else {
        sidebarMenu.isOpen = !sidebarMenu.isOpen;
      }
    }
  }

  function onClickSidebarToggleBtn() {
    sidebarMenu.toggle();
    sidebarMenu.type = "Auto";
    let sidebarBackDrop = document.querySelector(".e-sidebar__backdrop");
    let sidebar__toggleBtn__collapse = document.querySelector(
      "#btn_e-sidebar__toggleBtn__collapse"
    );
    let sidebar__toggleBtn__expand = document.querySelector(
      "#btn_e-sidebar__toggleBtn__expand"
    );
    if (
      isNotNullAndUndefined(sidebar__toggleBtn__collapse) &&
      isNotNullAndUndefined(sidebar__toggleBtn__expand)
    ) {
      sidebar__toggleBtn__collapse.classList.remove("hidden");
      sidebar__toggleBtn__expand.classList.add("hidden");
    }
    if (BrowserIsDevice === true && isNotNullAndUndefined(sidebarBackDrop)) {
      sidebarBackDrop.classList.remove("show");
    }
  }

  // };

  const onClickLeftMenuItem1 = (item: PortalTreeViewItemRow) => {
    if (isNotNullAndUndefined(item)) {
      updateTreeViewSelectExpandStateByID(item.UID);
    }
  };

  const onClickLeftMenuItem = useRecoilCallback(
    ({ set, snapshot }) =>
      async (item: PortalTreeViewItemRow) => {
        if (isNotNullAndUndefined(item)) {
          //if (item.ItemType === RAFWorkspaceRelatedtoType.View) {
          const prevCurrentView: RAFTreeViewMenu = await snapshot.getPromise(
            atomSelectedViewState(item.Entity)
          );
          const recentViewId =
            item.ItemType === RAFWorkspaceRelatedtoType.View
              ? item.UID
              : isNotNullAndUndefined(prevCurrentView)
              ? prevCurrentView.UID
              : null;

          const recentViewName =
            item.ItemType === RAFWorkspaceRelatedtoType.View
              ? item.Name
              : isNotNullAndUndefined(prevCurrentView)
              ? prevCurrentView.Name
              : null;

          const recentViewType =
            item.ItemType === RAFWorkspaceRelatedtoType.View
              ? null
              : isNotNullAndUndefined(prevCurrentView)
              ? prevCurrentView.Category
              : null;

          const viewMenuDataSource = await getViewsReportsDashboards(
            item.Entity,
            true,
            isDisableDashboardAnalytic(item.Entity),
            null
          );

          const currentView = await getDefaultActiveCurrentViewFromList(
            item.Entity,
            recentViewId,
            recentViewType ?? RAFReportType.Table,
            viewMenuDataSource,
            true,
            permissionValue,
            recentViewName
          );

          if (
            isNotNullAndUndefined(currentView) &&
            !deepEqual(prevCurrentView, currentView)
          ) {
            const currentModule = item.Entity;
            const enableListAndTableView =
              isNotNullAndUndefined(currentModule) &&
              (currentModule === CareEsioEntity.CareRecipient.EntityName ||
                currentModule === RAFEntityName.Team) &&
              isNotNullAndUndefined(currentView) &&
              currentView.Category === RAFReportType.Table
                ? true
                : false;

            let defaultListGridID = `grid3_${currentModule}_mainList`;
            if (enableListAndTableView) {
              const selectedGridViewState = await snapshot.getPromise(
                atomSelectedGridView(currentModule)
              );

              defaultListGridID = `grid3_${currentModule}_mainList${
                selectedGridViewState ?? ""
              }`;
            }

            set(atomSelectedViewState(currentModule), currentView);
            SetGridState(defaultListGridID, null, null);
          }

          if (isNotNullAndUndefined(currentView)) {
            const treeViewItem = getSelectedItemByCurrentView(
              currentView,
              item
            );
            if (isNotNullAndUndefined(treeViewItem)) {
              const currentViewID = treeViewItem.UID ?? treeViewItem.Name;
              updateTreeViewSelectExpandStateByID(currentViewID);
            } else {
              const isLoaded = await loadChildViewItem(item.UID);
              const treeViewItem = getSelectedItemByCurrentView(
                currentView,
                item
              );
              if (isNotNullAndUndefined(treeViewItem)) {
                const currentViewID = treeViewItem.UID ?? treeViewItem.Name;
                updateTreeViewSelectExpandStateByID(currentViewID);
              } else {
                updateTreeViewSelectExpandStateByID(item.UID);
              }
            }
          } else {
            updateTreeViewSelectExpandStateByID(item.UID);
          }
        }
      }
  );

  function nodeTemplate(item: PortalTreeViewItemRow) {
    let fontsize = "1em";
    if (item.Text === "home" || item.Name === "home") {
      fontsize = "1rem";
    }
    const itemType = item.ItemType;
    const displayName = item.Text ?? "na";
    const moduleIconName = getModuleAvatarIcon(
      item.Text === "home" || item.Name === "home" ? "home" : item.Entity
    );

    return (
      <Link
        to={item.URL}
        key={item.URL}
        // {...(item.Name === 'XeroIntegration'
        //   ? { target: '_blank' }
        //   : {})}
        id={ConvertToElementID("sidebarlist" + displayName)}
        onClick={(e) => {
          let isSelectedMenuItem = false;

          if (
            isNotNullAndUndefined(item.URL) &&
            item.URL.toLowerCase() === window.location.pathname.toLowerCase()
          ) {
            isSelectedMenuItem = true;
          }

          if (isSelectedMenuItem) {
            e.preventDefault();
          }
          onClickLeftMenuItem(item);
          if (
            BrowserIsDevice &&
            (isNotNullAndUndefined(item.ParentUID) ||
              item.HasChildren === false)
          ) {
            toggleclick();
          }
          // if ((item.ItemType === RAFWorkspaceRelatedtoType.View || item.HasChildren === false) && !isSelectedMenuItem && !IsNullOrWhiteSpace(item.URL)) {
          //     onClickLeftMenuItem(item);
          // } else {
          //     e.preventDefault();
          // }

          //   if(isNotNullAndUndefined(item)){
          //     console.log('item',item)
          //   const displayName = item.Text ?? "na";

          //   let element = document.getElementById(ConvertToElementID("sidebarlist" + displayName));
          //   console.log('element',element)
          //   if (isNotNullAndUndefined(element)) {
          //     element.scrollIntoView({
          //       behavior: "smooth",
          //       block: "start",
          //       inline: "nearest",
          //     });
          //   }
          // }
        }}
      >
        <div
          className={`menu-item-text`}
          // className={`menu-item-text d-flex ${isNotNullAndUndefined(item.ParentUID) ? "align-items-center" : "align-items-baseline"}`}
          // onClick={() =>
          //     onSelectViewItem(item)
          // }
        >
          <div className="e-sidebar__treeview__menuItem__iconDiv d-flex align-items-center position-relative">
            {/* <span
              className={
                item.Name === "home" || isNotNullAndUndefined(item.ParentUID)
                  ? "transparent-avatar avatar-text-square"
                  : getModuleAvatarClass(item.Name) + " avatar-text-square"
              }
              style={{
                width: "24px",
                height: "24px",
                minWidth: "24px",
                maxWidth: "24px",
                fontSize: fontsize,
                fontWeight: 600,
                //marginRight: "0.625em",
              }}
            > */}
            {isNotNullAndUndefined(item) &&
            isNotNullAndUndefined(item.IsExternalIcon) &&
            isNullOrUndefined(item.ParentUID) &&
            item.IsExternalIcon === true ? (
              <IconPickerItem
                icon={
                  isNotNullAndUndefined(item) &&
                  isNotNullAndUndefined(item.Icon)
                    ? (item.Icon as IconList)
                    : "FaBriefcase"
                }
                size={20}
                color="inherit"
                containerStyles={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            ) : isNotNullAndUndefined(moduleIconName) &&
              isNullOrUndefined(item.ParentUID) &&
              IsNotNullOrWhiteSpace(moduleIconName) ? (
              <span
                className={`e-sidebar__treeview__menuItem__icon far fa-${moduleIconName}`}
              ></span>
            ) : (
              <span
                className={
                  item.Name === "home"
                    ? "e-sidebar__treeview__menuItem__icon far fa-" + item.Icon
                    : isNotNullAndUndefined(item.ParentUID)
                    ? "fa-xxsm far fa-circle"
                    : "e-sidebar__treeview__menuItem__icon far fa-" + item.Icon
                }
              ></span>
            )}
            {/* </span> */}
          </div>
          <div
            className={`e-sidebar__treeview__menuItem__text ecllipseFirstLine${
              isNotNullAndUndefined(item.ParentUID) ? " ps-0" : ""
            }`}
          >
            {displayName}
          </div>
          {item.Name === "home" ? (
            <>
              {/* <UserFeedsContext.Consumer>
              {({ userFeeds }) => {
                return (
                  <div className="userFeed_badge ms-auto me-2">
                    {userFeeds && userFeeds.length > 0 && (
                      <TooltipComponent
                        content={`${userFeeds.length} new notifications`}
                        position="BottomCenter"
                        opensOn="Hover"
                      >
                        <span
                          className="e-badge e-badge-warning e-badge-circle"
                          id="homeCountDiv"
                        >
                          {userFeeds.length}
                        </span>
                      </TooltipComponent>
                    )}
                  </div>
                );
              }}
            </UserFeedsContext.Consumer> */}
              {/* <HomeMenuFeedsCount /> */}
            </>
          ) : item.Name === "inbox" ? (
            <div className="ms-2">
              <TooltipComponent content={"Under beta testing"}>
                <div className="raf_badge raf_18 raf_badge_rgba content_neutral_white raf_badge_beta">
                  <span>βeta</span>
                </div>
              </TooltipComponent>
            </div>
          ) : (
            (item.ItemType === RAFWorkspaceRelatedtoType.View ||
              item.ItemType === RAFWorkspaceRelatedtoType.Module) &&
            isNotNullAndUndefined(item.ParentUID) &&
            !BrowserIsDevice && (
              <div className="ms-auto">
                <RAFViewMoreMenuOptions
                  viewID={item.UID}
                  moduleName={item.Entity}
                  onSave={onChangeViewRow}
                  itemType={itemType}
                />
              </div>
            )
          )}
        </div>
      </Link>
    );
  }

  const nodeExpanding = async (args) => {
    if (isNotNullAndUndefined(args) && args.isInteracted == true) {
      const nodeDataID = isNotNullAndUndefined(args.nodeData)
        ? args.nodeData.id
        : null;
      if (isNotNullAndUndefined(nodeDataID)) {
        const isLoaded = await loadChildViewItem(nodeDataID);
        if (isNotNullAndUndefined(treeviewObj)) {
          const selectedNodes = treeviewObj.selectedNodes;
          if (isNotEmptyArray(selectedNodes)) {
            updateTreeViewSelectExpandStateByID(selectedNodes[0]);
          }
        }
      }
    }
  };

  const updateTreeViewSelectExpandStateByID = (nodeId: string) => {
    if (isNotNullAndUndefined(treeviewObj) && isNotNullAndUndefined(nodeId)) {
      const currentNode = getTreeViewNodeDataByNodeID(nodeId);
      const treeSelectedNodeID = isNotEmptyArray(treeviewObj.selectedNodes)
        ? treeviewObj.selectedNodes[0]
        : null;
      const treeExpandedNodesID = isNotEmptyArray(treeviewObj.expandedNodes)
        ? treeviewObj.expandedNodes[0]
        : null;
      if (isNotNullAndUndefined(currentNode)) {
        const hasChildren = isNotEmptyArray(currentNode.NodeChild)
          ? true
          : false;
        if (hasChildren === true) {
          if (treeExpandedNodesID !== currentNode.UID) {
            treeviewObj.expandedNodes = [currentNode.UID];
          }
        } else if (isNotNullAndUndefined(currentNode.ParentUID)) {
          if (treeExpandedNodesID !== currentNode.ParentUID) {
            treeviewObj.expandedNodes = [currentNode.ParentUID];
          }
        }
        treeviewObj.selectedNodes = [currentNode.UID];
      }
    }
  };

  const onNodeSelect = async (args) => {
    const nodeData = isNotNullAndUndefined(args) ? args.nodeData : null;
    const nodeDataID = isNotNullAndUndefined(nodeData) ? nodeData.id : null;
    if (isNotNullAndUndefined(nodeDataID)) {
      const isLoaded = await loadChildViewItem(nodeDataID);
      if (nodeData.HasChildren === true) {
        treeviewObj.expandedNodes = [nodeDataID];
      }
    }
  };

  const loadChildViewItem = (selectedNodeId: string) => {
    return new Promise<boolean>(async (resolve) => {
      let isLoaded = false;
      if (
        isNotNullAndUndefined(selectedNodeId) &&
        isNotNullAndUndefined(treeviewObj)
      ) {
        const allNodeDate: PortalTreeViewItemRow[] =
          treeviewObj.getTreeData() as any;
        const currentNode = getTreeViewNodeDataByNodeID(selectedNodeId);
        if (
          isNotNullAndUndefined(currentNode) &&
          isNotNullAndUndefined(currentNode.Entity) &&
          currentNode.HasChildren === true &&
          currentNode.ItemType === RAFWorkspaceRelatedtoType.Module
        ) {
          const currentNodeChildItems = isNotNullAndUndefined(currentNode)
            ? currentNode.NodeChild
            : [];
          const childItemInTree: PortalTreeViewItemRow[] = isNotEmptyArray(
            allNodeDate
          )
            ? allNodeDate.filter((x) => x.ParentUID === selectedNodeId)
            : [];
          const isChildExist =
            isNotEmptyArray(childItemInTree) ||
            isNotEmptyArray(currentNodeChildItems)
              ? true
              : false;
          if (isChildExist === false) {
            const selectedWorkspaceItem = portalTreeViewItems.find(
              (x) => x.UID === selectedNodeId
            );
            const entity = selectedWorkspaceItem.Entity;
            if (isNotNullAndUndefined(entity)) {
              const viewItems = await getUserViewsByModuleName(entity, true);
              const viewMenusRow = convertViewRowToWorkspaceViewItem(
                viewItems,
                entity,
                selectedWorkspaceItem.UID,
                selectedWorkspaceItem.URL,
                permissionValue
              );
              if (
                isNotEmptyArray(viewMenusRow) &&
                isNotNullAndUndefined(treeviewObj)
              ) {
                treeviewObj.addNodes(
                  viewMenusRow as any,
                  selectedNodeId.toString()
                );
                isLoaded = true;
              }
            }
          }
        }
      }
      resolve(isLoaded);
    });
  };

  const onTreeViewDataBound = () => {
    setTimeout(() => {
      if (isNotEmptyArray(portalTreeViewItems)) {
        const selectedItem = getSelectedItemAndParentId();
        if (isNotNullAndUndefined(selectedItem)) {
          onClickLeftMenuItem(selectedItem);
        }
      }
    }, 100);
  };

  const getTreeViewNodeDataByNodeID = (nodeId: string) => {
    let selectedItem: PortalTreeViewItemRow = null;
    if (isNotNullAndUndefined(treeviewObj)) {
      const allNodeDate: PortalTreeViewItemRow[] =
        treeviewObj.getTreeData() as any;
      if (isNotEmptyArray(allNodeDate) && isNotNullAndUndefined(nodeId)) {
        allNodeDate.forEach((item) => {
          if (item.UID === nodeId) {
            selectedItem = item;
          } else if (isNotEmptyArray(item.NodeChild)) {
            item.NodeChild.forEach((childItem) => {
              if (childItem.UID === nodeId) {
                selectedItem = childItem;
              }
            });
          }
        });
      }
    }
    return selectedItem;
  };

  const getSelectedItemByCurrentView = (
    currentView: RAFTreeViewMenu,
    item: PortalTreeViewItemRow
  ) => {
    let selectedItem: PortalTreeViewItemRow = null;
    if (isNotNullAndUndefined(treeviewObj)) {
      const allNodeDate: PortalTreeViewItemRow[] =
        treeviewObj.getTreeData() as any;
      if (isNotEmptyArray(allNodeDate) && isNotNullAndUndefined(currentView)) {
        const currentViewID =
          currentView.UID ?? `${item.Entity}_${currentView.Name}`;
        allNodeDate.forEach((item) => {
          if (item.UID === currentViewID) {
            selectedItem = item;
          } else if (isNotEmptyArray(item.NodeChild)) {
            item.NodeChild.forEach((childItem) => {
              if (childItem.UID === currentViewID) {
                selectedItem = childItem;
              }
            });
          }
        });
      }
    }
    return selectedItem;
  };

  const getSelectedItemAndParentId = () => {
    const currentPath = window.location.pathname.toLowerCase();
    let selectedItem: PortalTreeViewItemRow = null;
    if (isNotEmptyArray(portalTreeViewItems)) {
      portalTreeViewItems.forEach((item) => {
        if (
          isNotNullAndUndefined(currentPath) &&
          isNotNullAndUndefined(item.URL) &&
          item.URL.toLowerCase() === currentPath.toLowerCase()
        ) {
          selectedItem = item;
        } else if (isNotEmptyArray(item.NodeChild)) {
          item.NodeChild.forEach((childItem) => {
            if (
              isNotNullAndUndefined(currentPath) &&
              isNotNullAndUndefined(childItem.URL) &&
              childItem.URL.toLowerCase() === currentPath.toLowerCase()
            ) {
              selectedItem = childItem;
            }
          });
        }
      });
    }
    return selectedItem;
  };

  const onChangeViewRow = (recentViewId: string, itemEntity: string) => {
    workspaceLeftItemsContext.updateCurrentWorkspaceLeftMenuItems(
      recentViewId,
      itemEntity
    );
  };

  const onChangeMenuCategory = (selectedMenu) => {
    if (selectedMenu !== currentMenuCategory) {
      setTimeout(() => {
        workspaceLeftItemsContext.updateCurrentWorkspaceLeftMenuItems();
      }, 50);
    }
  };

  const backToMainMenu = () => {
    // setSessionStorage(
    //     RMStorageKey.recentClickedMenu,
    //     true,
    //     JSON.stringify([MenuCategory.Default])
    // );
    navigateToDashboard(navigate);
    onChangeMenuCategory(MenuCategory.Default);
  };

  const onDoubleClickProductLogo = () => {
    const canImpersonateUser = loginUserContext.canImpersonateUser;
    const isImpersonatedUser = loginUserContext.isImpersonatedUser;
    if (canImpersonateUser || isImpersonatedUser) {
      navigate("/switchtenant");
    }
  };

  // if (isNullOrUndefined(currentWorkspace)) return <div></div>;

  // else if (BrowserIsDevice && (currentTenantName === RAFTenantName.CareESIO || isCareESIOPartner === true) && props.mode !== 'FullScreen') return <div></div>;
  return (
    <>
      <aside
        id="sideTree"
        className={`sidebar-treeview e-sidebar__treeview section__container${
          props.theme === "White" ? " whiteTheme" : ""
        }`}
      >
        <div className="brandLogoDiv raf-lg d-flex justify-content-center align-items-center">
          <img
            src={props.theme === "White" ? productLogo : productLogoReversal}
            alt="Product Logo"
            className="e-sidebar__treeview__logo pointer full_logo"
            onDoubleClick={onDoubleClickProductLogo}
          />
          <img
            src={
              props.theme === "White"
                ? productShortLogo
                : productShortLogoReversal
            }
            alt="Product Logo"
            className="e-sidebar__treeview__logo pointer short_logo"
            onDoubleClick={onDoubleClickProductLogo}
          />
          <div style={{ right: "1rem" }} className="position-absolute">
            <RAFButtonComponent
              className="e-outline square-btn e-sidebar__treeview__toggleBtn"
              id="e-sidebar__toggleBtn__collapse"
              onClick={() => toggleclick()}
              btnContentTemplate={
                <img
                  src={sidebarToggleImg}
                  alt="Sidebar toggle button"
                  width={16}
                />
              }
            ></RAFButtonComponent>
            <RAFButtonComponent
              className="e-outline square-btn e-sidebar__treeview__toggleBtn hidden"
              onClick={() => onClickSidebarToggleBtn()}
              id="e-sidebar__toggleBtn__expand"
              btnContentTemplate={
                <img
                  src={sidebarToggleImg}
                  alt="Sidebar toggle button"
                  width={16}
                />
              }
            ></RAFButtonComponent>
          </div>
          {/* <ButtonComponent
            type="button"
            iconCss="fas fa-angles-left"
            cssClass="custom-button-sm primary-custom-button text-white ms-2"
            style={{ borderColor: "#004d8e" }}
            onClick={() => toggleclick()}
          ></ButtonComponent> */}
        </div>
        <div className="e-sidebar__treeview__header" key={currentMenuCategory}>
          {isNullOrUndefined(currentMenuCategory) ||
          currentMenuCategory === MenuCategory.Default ||
          currentMenuCategory === MenuCategory.ContentManagement ? (
            <div className="e-sidebar__profileCardDiv w-100">
              <RAFProfileCard
                arrowTipClass="e-tip-left"
                uiMode="fullBtn"
                onUpdatMenuCategory={(menu) => onChangeMenuCategory(menu)}
              />
            </div>
          ) : (
            <div
              className="pointer text-white e-sidebar__treeview__backBtn"
              onClick={() => backToMainMenu()}
            >
              <div className="row gx-2">
                <div className="col-auto">
                  <i className="px-1 fass fa-arrow-left-long"></i>
                </div>
                <div className="col e-sidebar__treeview__backBtn__text">
                  Back to Main Menu
                </div>
              </div>
            </div>
          )}
        </div>
        {/* 
        <div className="e-sidebar__treeview__subtitle body_3">
          Menu
        </div> */}
        <div
          id="mainTree"
          className="section__div customScrollBar"
          key={leftMenuItemOuterDivKey}
        >
          <TreeViewComponent
            ref={(treeview) => (treeviewObj = treeview)}
            fields={{
              dataSource: portalTreeViewItems as any,
              id: propertyOf<PortalTreeViewItemRow>("UID"),
              parentID: propertyOf<PortalTreeViewItemRow>("ParentUID"),
              hasChildren: propertyOf<PortalTreeViewItemRow>("HasChildren"),
              text: propertyOf<PortalTreeViewItemRow>("Text"),
              child: propertyOf<PortalTreeViewItemRow>("NodeChild"),
              iconCss: propertyOf<PortalTreeViewItemRow>("Icon"),
            }}
            nodeTemplate={nodeTemplate.bind(this)}
            nodeExpanding={nodeExpanding}
            //nodeSelected={onNodeSelect}
            loadOnDemand={true}
            dataBound={onTreeViewDataBound}
            enablePersistence
          />
        </div>
      </aside>
      {/* <ButtonComponent
        type="button"
        iconCss="fas fa-angles-right"
        id="e-sidebar__toggleBtn"
        cssClass={BrowserIsDevice ? "e-sidebar__toggleBtn" : "e-sidebar__toggleBtn hidden"}
        onClick={() => toggleclick()}
      ></ButtonComponent> */}
      <div
        className="e-sidebar__backdrop"
        onClick={() => toggleclick(true)}
      ></div>
    </>
  );
}
export default React.memo(NavigationMenuTreeView);
