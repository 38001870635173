import { PropsWithChildren } from 'react';
import { GridColumnTemplates } from '../RAFComponents/Grid/RAFSFGridUtils';
import { isNotNullAndUndefined, IsNullOrWhiteSpace, wrapChildrenWith } from '../RAFComponents/helpers/utils';
import { ModuleGridColumnTemplates } from './ACRAFModuleGridColumnTemplates';

//this file is used for grid template columns 
//DO NOT delete this file or DO NOT change the location 
//DO NOT change props or methods except the property abc,getGridColumnTemplatesByModule which will vary according to project


export const getGridColumnTemplatesByModule = (moduleName: string): GridColumnTemplates[] => {
    let retVal: GridColumnTemplates[] = [];
    if (!IsNullOrWhiteSpace(moduleName)) {
        // let allModuleGridColumnTemplates: { moduleName: string, templates: GridColumnTemplates[] }[] = ModuleGridColumnTemplates();
        const allModuleGridColumnTemplates: { moduleName: string, templates: GridColumnTemplates[]; }[] = ModuleGridColumnTemplates();
        if (isNotNullAndUndefined(allModuleGridColumnTemplates) && allModuleGridColumnTemplates.length > 0) {
            const obj1 = allModuleGridColumnTemplates.find(x => x.moduleName.toLowerCase() === moduleName.toLowerCase());
            if (isNotNullAndUndefined(obj1)) {
                retVal = obj1.templates;
            }
        }
    }
    return retVal;
};

interface IProps {

}

function RAFGridTemplates({ ...props }: PropsWithChildren<IProps>) {

    // const children1 = wrapChildrenWith(props.children, {
    //     //templateHelpers: this,
    //     templateHelpers: new ACRAFGridTemplates(props),
    //     ...props
    // });

    const children = wrapChildrenWith(props.children, {
        ...props
    });
    return <div className="h-100">{children}</div>;
}

export default RAFGridTemplates;