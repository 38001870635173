import React from "react";
import { Component } from "react";

import acloading from "../../assets/LoaderIconCircle.svg";
import { IsNullOrWhiteSpace } from "../../RAFComponents/helpers/utils";

class ACLoadingPanel extends Component<{ loadingText?: string; }> {

    private loadingText = this.props.loadingText;

    render() {

        let loadingText = this.props.loadingText;

        return (

            <div className="row g-0 wrapper-content loadingPanel">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                    <div className="sk-spinner sk-spinner-double-bounce hidden">
                        <div className="sk-double-bounce1"></div>
                        <div className="sk-double-bounce2"></div>
                    </div>
                    <img src={acloading} width="52" alt="Loading" />
                    <span className="loadingText"> {IsNullOrWhiteSpace(this.loadingText) ? "Loading..." : loadingText}</span>
                </div>
            </div>
        );
    }
}

export default ACLoadingPanel;
