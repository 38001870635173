import React, { PropsWithChildren, Suspense } from 'react';
import { IDialogProps } from '../RAFComponents/helpers/utils';
import RafIndexPageHeader from '../RAFModules/Common/List/RafIndexPageHeader';
import ACLoadingPanel from '../components/shared/ACLoadingPanel';
import SwitchTenant from './SwitchTenant';

interface IProps {
    mode?: 'Page' | 'Dialog',
}

function SwitchTenantPage({ ...props }: PropsWithChildren<IProps & IDialogProps>) {

    const switchTenantDialogContent = () => {
        return (
            <SwitchTenant
                mode='Page'
                isActive />
        );
    };

    return (
        <div
            className="page-flex-container"
        >
            <Suspense
                fallback={<ACLoadingPanel loadingText="Loading..." />}
            >
                <RafIndexPageHeader
                    title={"Switch Tenant"}
                    hideSecondaryHeader
                />
            </Suspense>
            <div className={"grid-content-container"}>
                {switchTenantDialogContent()}
            </div>
        </div>
    );
}

export default React.memo(SwitchTenantPage);
