import React, { Component } from "react";
//import Slider from "infinite-react-carousel";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
// import { default as productLogo } from '../../assets/skytrust_logo.png';
//import { default as bannerLogo } from './../../images/classic_signin_illustration_optimized.png';

class LoginSlider extends Component {
    render() {
        //const bannerLogo = `${window.location.origin}/Content/img/classic_signin_illustration_optimized.png`;
        const bannerLogo = `${window.location.origin}/Content/img/106902930_m.jpg`;


        return (
            <div>
                {/*<Slider {...settings} className="sliderDiv hidden">
                    <div className="bgFirstSlide min-vh-100">
                        <h3>Slide 1</h3>
                    </div>
                    <div className="bgSecondSlide min-vh-100">
                        <h3>Slide 2</h3>
                    </div>
                    <div className="bgThirdSlide min-vh-100">
                        <h3>Slide 3</h3>
                    </div>
                </Slider>*/}
                <div className="login-leftPanel">
                    {/*<div className="d-flex justify-content-center topHeader">
                        <span>Visit our new website!</span>
                        <form action="https://skytrust.com.au/">
                            <ButtonComponent type="submit" cssClass='e-flat e-default mx-2'>SKYTRUST.COM.AU</ButtonComponent>
                        </form>
                    </div>
                    */}
                    <div className="panel-content customScrollBar">
                        <div>
                            {/* <div className="my-3 hidden">
                                <img className="w-50" src={productLogo} alt="Skytrust" />
                            </div> */}
                            <div className="d-flex align-items-center flex-column ">
                                {/* <div className="titleHeader mb-3 hidden">
                                    <h5 className="text-primary">Skytrust is a diverse cloud-based organisational management system which drives performance and provides dashboard based situational awareness.</h5>
                                </div> */}
                                <img src={bannerLogo} alt="WorkESIO" />
                            </div>
                        </div>
                    </div>
                    {/* <div className="d-flex justify-content-center footer hidden">
                        <span>Visit our new website!</span>
                        <form action="https://skytrust.com.au/">
                            <ButtonComponent type="submit" cssClass='e-flat e-default e-outline btn-trial mx-3'>SKYTRUST.COM.AU</ButtonComponent>
                        </form>
                    </div> */}
                </div>
                <div className="wrapper hidden">
                    <div className="header">Header</div>
                    <div className="content1 customScrollBar">
                        <div>Content</div>
                    </div>
                    <div className="footer">Footer</div>
                </div>
            </div>
        );
    }
}

export default LoginSlider;