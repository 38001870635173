import React from 'react';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { PropsWithChildren, useContext } from "react";
import { FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFLookupCC from "../../../RAFComponents/Inputs/RAFLookupCC";
import RAFTextBox from "../../../RAFComponents/Inputs/RAFTextBox";
import { RAFDefaultFieldProps, RAFFieldProps, RAFFormContext } from "../../../RAFComponents/Inputs/RFFUtils";
import { AddressType } from "./AddressRow";
import { CityRow } from "./CityRow";
import { CountryRow } from "./CountryRow";
import { StateRow } from "./StateRow";
import { RAFAttributesContext } from '../../../RAFComponents/Providers/RAFAttributeRelatedListProvider';
import { GetFieldsDisplayName } from '../../../RAFComponents/helpers/AppHelper';
import RAFFieldLabel from '../../../RAFComponents/Inputs/RAFFieldLabel';

interface IProps {
    showAdd?: boolean;
    showAddressType?: boolean;
}

function RAFAddress1<T>(
    { field,
        label,
        width,
        labelClassName,
        description,
        showAdd = true,
        showAddressType = true,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
        //  children 
    }: PropsWithChildren<RAFFieldProps<T> & IProps>,
) {
    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

    const onAddClick = (field: keyof T | string) => {
        if (isNotNullAndUndefined(rafFormContextValue) && isNotNullAndUndefined(rafFormContextValue.form) && isNotNullAndUndefined(rafFormContextValue.form.mutators)) {
            rafFormContextValue.form.mutators.push(field.toString(), undefined);
        }
    };

    return (
        // <div className="app-container p-0" id={"rafdiv" + field.toString()} >
        <div className="app-container p-0" id={`rafdiv${field.toString()}`} >
            <div className="d-flex justify-content-between">
                {showLabel && showLabel === true &&
                    <RAFFieldLabel
                        field={field}
                        label={label}
                        required={required}
                        labelClassName={labelClassName}
                        description={description}
                    ></RAFFieldLabel>
                }
            </div>
            <div className="list">
                <FieldArray name={field.toString()}>
                    {({ fields }) =>
                        fields.map((name, index) => (
                            <div key={name}>
                                <div className="mb-2 addressSection">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="w-100">
                                                {showAddressType && <div className="row d-flex align-items-center px-0 m-0 flex-nowrap">
                                                    <div className="col-sm-6 col ps-0 pe-0">
                                                        <RAFDropdownCC field={`${name}.AddressType`} showLabel={false} placeholder="Select Type">
                                                            <RAFChoiceOption label="Postal" value={AddressType.PostalAddress} />
                                                            <RAFChoiceOption label="Residential" value={AddressType.ResidentialAddress} />
                                                        </RAFDropdownCC>
                                                    </div>
                                                    <div className="col-auto col-sm-6 pe-0 d-flex align-self-start justify-content-end mt-2">
                                                        <ButtonComponent type="button" onClick={() => fields.remove(index)} title="Click to remove" className="removeItem px-2">
                                                            <span className="fas fa-trash"></span>
                                                        </ButtonComponent>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <RAFTextBox field={`${name}.AddressLine1`} showLabel={false} placeholder="Address line1" width={width} />
                                        </div>
                                        <div className="col-12 d-none">
                                            <RAFTextBox field={`${name}.AddressLine2`} showLabel={false} placeholder="Address line2" width={width} />
                                        </div>
                                        <div className="col-6">
                                            <RAFLookupCC field={`${name}.City`} showLabel={false} type={CityRow} url='City/Lookup' width={width} placeholder="Select city" />
                                        </div>
                                        <div className="col-6">
                                            <RAFLookupCC field={`${name}.State`} showLabel={false} type={StateRow} url='State/Lookup' width={width} placeholder="Select state" />
                                        </div>
                                        <div className="col-6">
                                            <RAFLookupCC field={`${name}.Country`} showLabel={false} type={CountryRow} url='Country/Lookup' width={width} placeholder="Select country" />
                                        </div>
                                        <div className="col-6">
                                            <RAFTextBox field={`${name}.PostalCode`} showLabel={false} placeholder="Postal code" width={width} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </FieldArray>
                {showAdd &&
                    <div className="mx-2 mb-3">
                        <button type="button" className="link-button" onClick={() => onAddClick(field)}> Add Address </button>
                    </div>
                }
            </div>
        </div>
    );
}

export default RAFAddress1;

