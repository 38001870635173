import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilCallback } from "recoil";
import { msalInstance } from "../../..";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import {
  isCurrentTenantCareESIOPartner,
  isDisableDashboardAnalytic,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  RAFEventName,
  triggerRAFEvent,
} from "../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  IsNotNullOrWhiteSpace,
  deepEqual,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../RAFComponents/helpers/utils";
import { getServiceTransactionCollectionNameFromStorage } from "../../../RAFModules/ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import {
  atomSelectedViewState,
  getDefaultActiveCurrentViewFromList,
  getViewsReportsDashboards,
} from "../../../RAFModules/Common/List/IndexHelper";
import { CurrentWorkspaceContext } from "../../../RAFModules/Common/Providers/WorkspaceContextProvider";
import {
  CareEsioEntity,
  CareEsioTab,
} from "../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  RAFReportType,
  RAFTenantName,
  RAFTreeViewMenu,
} from "../../../constants/Common/Constants";

function RAFFooterNavigationMenu() {
  const currentWorkspaceContext = React.useContext(CurrentWorkspaceContext);
  const currentWorkspace = isNotNullAndUndefined(currentWorkspaceContext)
    ? currentWorkspaceContext.currentWorkspace
    : null;

  const rolePermissionsContext = React.useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  useEffect(() => {
    if (IsNotNullOrWhiteSpace(currentWorkspace)) {
      setActiveClassName();
    }
  }, [currentWorkspace, location.pathname]);

  let navigate = useNavigate();

  const headertext = [
    { id: "Menu", text: "Menu", iconCss: "fas fa-bars" },
    {
      id: CareEsioTab.ShiftLog.Value,
      text: `My ${getServiceTransactionCollectionNameFromStorage()}`,
      iconCss: "fas fa-calendar-days",
      path: `/bookings`,
    },
    { id: "Home", text: "Home", iconCss: "fas fa-home", path: "/" },
    {
      id: CareEsioEntity.care_recipient.EntityName,
      text: CareEsioEntity.care_recipient.CollectionName,
      iconCss: "fas fa-hand-holding-heart",
      path: `/raf/list/${CareEsioEntity.CareRecipient.EntityName}`,
    },
    {
      id: CareEsioTab.Discussions.Value,
      text: CareEsioTab.Discussions.CollectionName,
      iconCss: "fas fa-envelope",
      path: `/Inbox`,
    },
  ];

  const onSelected = useRecoilCallback(({ set, snapshot }) => async (item) => {
    if (isNotNullAndUndefined(item)) {
      if (item["id"] === "Menu") {
        triggerRAFEvent(RAFEventName.ToggleSideMenu, null);
      } else if (item["id"] === CareEsioEntity.CareRecipient.EntityName) {
        const prevCurrentView: RAFTreeViewMenu = await snapshot.getPromise(
          atomSelectedViewState(item["id"])
        );
        const viewMenuDataSource = await getViewsReportsDashboards(
          item["id"],
          true,
          isDisableDashboardAnalytic(item["id"]),
          null
        );
        const currentView = await getDefaultActiveCurrentViewFromList(
          item["id"],
          null,
          RAFReportType.Table,
          viewMenuDataSource,
          true,
          permissionValue,
          null
        );

        if (
          isNotNullAndUndefined(currentView) &&
          !deepEqual(prevCurrentView, currentView)
        ) {
          const currentModule = item["id"];
          set(atomSelectedViewState(currentModule), currentView);
        }

        setActiveClassName(item);
        navigate(item["path"]);
      } else {
        setActiveClassName(item);
        navigate(item["path"]);
      }
    }
  });

  const setActiveClassName = (item?) => {
    let selectedItem;
    if (isNotNullAndUndefined(item)) {
      selectedItem = item;
    }
    else {
      const path = window.location.pathname;
      if (path === "/") {
        selectedItem = headertext[2];
      }
      else {
        selectedItem = headertext.slice(3).find((x) => path.includes(x.path));
        if (isNullOrUndefined(selectedItem)) {
          selectedItem = path.includes(headertext[1].path) ? headertext[1] : null;
        }
      }
    }
    // Get all li elements whose id starts with 'footer_tab_item_'
    const items = document.querySelectorAll('li[id^="footer_tab_item_"]');
    // Remove the 'active' class from each item
    items.forEach((item) => item.classList.remove("active"));
    //Add a active class name in selected item
    if (IsNotNullOrWhiteSpace(selectedItem)) {
      let selectedFooterTabItem = document.getElementById(
        `footer_tab_item_${selectedItem.id}`
      );
      if (isNotNullAndUndefined(selectedFooterTabItem)) {
        selectedFooterTabItem.classList.add("active");
      }
    }
  };

  let currentTenantName =
    isNotNullAndUndefined(msalInstance) &&
      isNotNullAndUndefined(msalInstance.currentTenantName)
      ? msalInstance.currentTenantName
      : "";

  if (isNullOrUndefined(currentWorkspace)) return <></>;
  else if (
    BrowserIsDevice &&
    (currentTenantName === RAFTenantName.CareESIO ||
      isCurrentTenantCareESIOPartner() === true)
  ) {
    return (
      <div>
        <div className="footer_tab_outer">
          <ul className="footer_tab_items">
            {headertext.map((item, index) => {
              return (
                <li
                  key={index}
                  className="footer_tab_item"
                  onClick={() => onSelected(item)}
                  id={`footer_tab_item_${item.id}`}
                >
                  <div className="footer_tab_item_icon">
                    <i className={item.iconCss}></i>
                  </div>
                  <div className="footer_tab_item_text">
                    <span>{item.text}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        {/* <TabComponent ref={(tab) => (tabObj = tab)} cssClass='orientation-tab footer-tab hide-first-item' headerPlacement={'Bottom'}
                selected={(e) => {
                    onSelcted(e);
                }}>
                <TabItemsDirective>
                    <TabItemDirective header={headertext[0]} content={tabContent.bind(this)} />
                    <TabItemDirective header={headertext[1]} content={tabContent.bind(this)} />
                    <TabItemDirective header={headertext[2]} content={tabContent.bind(this)} />
                    <TabItemDirective header={headertext[3]} content={tabContent.bind(this)} />
                    <TabItemDirective header={headertext[4]} content={tabContent.bind(this)} />
                    <TabItemDirective header={headertext[5]} content={tabContent.bind(this)} />
                </TabItemsDirective>
            </TabComponent> */}
      </div>
    );
  } else {
    return <></>;
  }
}
export default React.memo(RAFFooterNavigationMenu);
