import { PropsWithChildren } from 'react';
import RAFDropdownCC from '../../../RAFComponents/Inputs/RAFDropdownCC';
import { RAFDefaultFieldProps, RAFDropdownFieldProps } from '../../../RAFComponents/Inputs/RFFUtils';
import { RAFAttributesContext } from '../../../RAFComponents/Providers/RAFAttributeRelatedListProvider';
import { GetFieldsDisplayName } from '../../../RAFComponents/helpers/AppHelper';
import { isNotNullAndUndefined } from '../../../RAFComponents/helpers/utils';
import { QueryAttributeJM } from '../../../RAFComponents/models/Common/QueryAttributeJM';

//const RAFTextBox = ({ field, label, required }) => {
interface IProps {
    moduleName?: string;
    emptyString?: string;
    queryAttribute?: QueryAttributeJM;
    hasChild?: boolean;
    displayType?: "Dropdown" | "Default";
    customDropdownBtnClassname?: string;
    displayDropdownType?:
    | "customDropdownBtn"
    | "customDropdownBtnForMinCount"
    | "colorDropdown"
    | "Default";
    addEmpty?: boolean;
}

function ACDropdown<T>(
    { field, label, width,
        onChanged, isColorOption, children, moduleName,
        emptyString, allowAdd, hasChild,
        customDropdownBtnClassname, displayType,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
        disabled = RAFDefaultFieldProps.disabled,
        showClearButton = RAFDefaultFieldProps.showClearButton,
        validate = RAFDefaultFieldProps.validate,
        ...props }: PropsWithChildren<RAFDropdownFieldProps<T> & IProps>,
) {
    // let showAddBtn = isNotNullAndUndefined(allowAdd) ? allowAdd : true;
    const showAddBtn = isNotNullAndUndefined(allowAdd) ? allowAdd : true;

    return (
        <div>
            {/* <div className="row align-items-center" id={"rafdiv" + field.toString()} > */}
            <div className="row align-items-center" id={`rafdiv${field.toString()}`} >
                <div className="col-12 d-flex">
                    {showLabel && showLabel === true &&
                        <RAFAttributesContext.Consumer>
                            {({ queryAttributes, attributeRelatedList }) => {
                                return (
                                    <label
                                        className={required ? "form-label required" : "form-label"}
                                    >
                                        {
                                            GetFieldsDisplayName(
                                                queryAttributes,
                                                field.toString(),
                                                label, attributeRelatedList
                                            )}{" "}
                                    </label>
                                );
                            }}
                        </RAFAttributesContext.Consumer>
                    }
                </div>
                <div className="col-12 d-flex">
                    <div className="w-100">
                        <RAFDropdownCC<T> field={field} label={label} required={required} displayDropdownType={props.displayDropdownType} showLabel={false} allowAdd={showAddBtn} hasChild={hasChild} customDropdownBtnClassname={customDropdownBtnClassname} displayType={displayType} children={children} width={width && width !== null ? width : '100%'} validate={validate} disabled={disabled} onChanged={onChanged} isColorOption={isColorOption} moduleName={moduleName} emptyString={emptyString} {...props} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ACDropdown;