import { CareEsioEntity } from "../../constants/CareESIO/CareEsioConstants";
import { RAFEntityName } from "../../constants/Common/EntityConstants";


export const getModuleAvatarClass = (moduleName) => {
    const avatarClasses = {
        [RAFEntityName.Contact]: "contact-avatar",
        [RAFEntityName.Account]: "company-avatar",
        [RAFEntityName.Deal]: "deal-avatar",
        [RAFEntityName.Ticket]: "ticket-avatar",
        [RAFEntityName.Email]: "email-avatar",
        [RAFEntityName.Task]: "task-avatar",
        [RAFEntityName.User]: "user-avatar",
        [RAFEntityName.Team]: "team-avatar",
        [RAFEntityName.UserSignature]: "usersignature-avatar",
        [RAFEntityName.Contract]: "contract-avatar",
        [RAFEntityName.BusinessProcess]: "bp-avatar",
        [RAFEntityName.BusinessProcessTemplate]: "bp-avatar",
        [RAFEntityName.ContentLibrary]: "document-avatar",
        [RAFEntityName.FormLibrary]: "form-library-avatar",
        [RAFEntityName.Template]: "template-avatar",
        'module': "module-avatar",
        'workflow': "workflow-manager-avatar",
        [RAFEntityName.View]: "view-avatar",
    };

    return avatarClasses[moduleName] || `${moduleName}-avatar default-avatar`;
};

export const getModuleAvatarIcon = (moduleName) => {
    const avatarIcons = new Map([
        ['home', "home"],
        [RAFEntityName.Task, "bars-progress"],
        [RAFEntityName.Contact, "address-card"],
        [RAFEntityName.Account, "building"],
        [RAFEntityName.Deal, "circle-dollar-to-slot"],
        [RAFEntityName.Ticket, "ticket"],
        [RAFEntityName.ContentLibrary, "file-alt"],
        [RAFEntityName.Email, "envelope"],
        [RAFEntityName.BusinessProcessTemplate, "group-arrows-rotate"],
        [RAFEntityName.BusinessProcess, "group-arrows-rotate"],
        [RAFEntityName.BusinessProcessType, "group-arrows-rotate"],
        [RAFEntityName.Category, "group-arrows-rotate"],
        [RAFEntityName.FormLibrary, "file-invoice"],
        [RAFEntityName.UserSignature, "file-signature"],
        [RAFEntityName.User, "user"],
        [RAFEntityName.Team, "users"],
        [RAFEntityName.Role, "list-ol"],
        [RAFEntityName.Portal, "briefcase"],
        [RAFEntityName.View, "clipboard-list"],
        [RAFEntityName.Complaint, "fa fa-exclamation-triangle"],
        [RAFEntityName.Note, "Comments"],
        [RAFEntityName.Activity, "Comments"],
        [RAFEntityName.VersionHistory, "history"],
        [RAFEntityName.Document, "file-alt"],
        [RAFEntityName.DataList, "list-check"],
        [CareEsioEntity.CareNDISPriceGuide.EntityName, "money-bill-1-wave"],
        [CareEsioEntity.CarePriceList.EntityName, "hand-holding-dollar"],
        [RAFEntityName.TenantSettings, "cog"],
    ]);

    return avatarIcons.get(moduleName) || 'file-alt';
};